import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { UnexpectedError } from 'domain/errors';
import {
  BulkActionsContactsRequest,
  BulkActionsContactsUseCase,
  BulkActionsResponse,
} from 'domain/usecases/contact/bulk-actions-contacts-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteBulkActionsContacts implements BulkActionsContactsUseCase {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient,
    private readonly toastService: ToastService
  ) {}

  async execute(
    contacts: BulkActionsContactsRequest
  ): Promise<BulkActionsResponse> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      url: this.url,
      method: 'patch',
      headers: {
        Authorization: `Bearer ${token}`,
        Tenant: tenant,
        'Content-Type': 'application/json',
      },
      body: {
        ids: contacts.ids,
        tags: contacts.tags,
        user_id: contacts.user_id,
      },
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        this.toastService.show(`Dados atualizados com sucesso!`, {
          type: 'success',
        });
        return {
          bulkActions: response.body,
        };

      default:
        this.toastService.show('Erro ao atualizar os dados!', {
          type: 'error',
        });
        throw new UnexpectedError('Erro ao atualizar os dados!');
    }
  }
}
