import React, { ReactElement, useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  RichText,
  Typography,
} from 'mino-ui';
import { WrapButtons, WrapContent, CallLink } from '../styles';
import MessagePrimary from '../icons/messageButtonPrimary.svg';
import MessageSecondary from '../icons/messageButtonSecondary.svg';
import Clock from '../icons/clock.svg';
import WhatsappIcon from '../icons/wpp.svg';
import { ActivityContext } from 'presentation/pages/Activity/context/ActivityContext';
import { useForm } from 'react-hook-form';
import { isMobile } from 'react-device-detect';
import { UploadFile } from './UploadFile';
import { ActivityStatus, ActivityType } from 'domain/entity/IActivity';

type ActivityPayload = {
  title: string;
  description: string;
  start: Date;
  end: Date;
  status: ActivityStatus;
  type: ActivityType;
};

const MessageTab = (): ReactElement => {
  const [openWpp, setOpenWpp] = useState(false);

  const [descriptionEditor, setDescriptionEditor] = useState(
    'Registro de mensagem'
  );

  const {
    load,
    handleSubmit,
    setScheduleActivityType,
    setOpenSchedule,
    phoneNumbers,
    files,
    setFiles,
  } = useContext(ActivityContext);

  const hasContacts = phoneNumbers && phoneNumbers?.length > 0;

  async function submitActivity(data: ActivityPayload) {
    const { title, description } = data;
    try {
      await handleSubmit({
        ...data,
        description: title == 'Mensagem' ? descriptionEditor : description,
      });

      setDescriptionEditor('');
    } catch (error) {
      console.error(error);
    }
  }

  const { handleSubmit: handleFormSubmit } = useForm<ActivityPayload>({
    defaultValues: {
      title: 'Mensagem',
      type: ActivityType.MESSAGE,
      description: descriptionEditor,
      start: new Date(),
      end: new Date(),
      status: ActivityStatus.FINISHED,
    },
  });

  function handleSendMessage(number: string) {
    const getOnlyNumbers = number.replace(/\D/g, '');
    const codeNumber = getOnlyNumbers.slice(0, 3);
    const verifyCode = codeNumber.includes('55');

    if (!verifyCode) {
      const wpp = `55${getOnlyNumbers}`;
      window.open(`https://wa.me/${wpp}`, '_blank');
    } else {
      window.open(`https://wa.me/${getOnlyNumbers}`, '_blank');
    }

    submitActivity({
      title: 'Mensagem enviada pelo WhatsApp',
      type: ActivityType.MESSAGE,
      description: 'Conversa iniciada pelo WhatsApp',
      start: new Date(),
      end: new Date(),
      status: ActivityStatus.FINISHED,
    });
  }

  useEffect(() => {
    setScheduleActivityType(ActivityType.MESSAGE);
  }, [setScheduleActivityType]);

  return (
    <WrapContent>
      <form onSubmit={handleFormSubmit(submitActivity)}>
        <RichText
          onChange={(e) => setDescriptionEditor(e)}
          value={descriptionEditor}
        />

        <UploadFile files={files} setFiles={setFiles} />

        <WrapButtons>
          {phoneNumbers && phoneNumbers?.length > 0 ? (
            <Button
              size="medium"
              text="WhatsApp"
              icon={WhatsappIcon}
              variant="primary"
              type="button"
              onClick={() => setOpenWpp(true)}
              noWrap
            />
          ) : null}

          <Button
            size="medium"
            text="Registrar Mensagem"
            loading={load}
            icon={hasContacts ? MessageSecondary : MessagePrimary}
            variant={hasContacts ? 'ghostSecondary' : 'primary'}
            type="submit"
            noWrap
          />
          <Button
            size="medium"
            text="Agendar Mensagem"
            icon={Clock}
            type="button"
            variant="ghostSecondary"
            onClick={() => setOpenSchedule(true)}
            noWrap
          />
        </WrapButtons>
      </form>
      {phoneNumbers && phoneNumbers?.length > 0 ? (
        <Dialog
          open={openWpp}
          modal
          onOpenChange={(v) => setOpenWpp(v)}
          title="Para qual número enviar mensagem?"
          content={
            <Card padding="0" direction="column">
              <Typography noWrap variant="body">
                Confira se o número de telefone está correto antes de
                prosseguir.
              </Typography>
              {phoneNumbers.map((phone, index) => (
                <CallLink
                  key={index}
                  onClick={() => handleSendMessage(phone.phone)}
                >
                  <Box gap={8} alignItems="center" style={{ maxWidth: '50%' }}>
                    <Avatar name={phone.name} />
                    <Typography
                      ellipsis={!isMobile}
                      variant="body"
                      color="primary"
                    >
                      {phone.name}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="body" color="primary">
                      {phone.phone}
                    </Typography>
                  </Box>
                </CallLink>
              ))}
            </Card>
          }
        />
      ) : null}
    </WrapContent>
  );
};
export default MessageTab;
