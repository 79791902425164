import React, { useCallback, useEffect, useState } from 'react';
import { NewDealsReportResponseDTO } from 'domain/usecases/report/new-deals-report-use-case';
import { makeNewDealsReportUseCase } from 'main/factories/usecases/report/new-deals-report-factory';
import { Panel } from 'presentation/pages';
import { FunnelsDealsResponseDTO } from 'domain/usecases/deal/report-funnels-deals.use-case';
import { LostReasonsResponseDTO } from 'domain/usecases/deal/report-lost-deals.use-case';
import { makeGetLostReasonsReport } from '../usecases/deal/get-lost-deals-report';
import { UsersDealsResponseDTO } from 'domain/usecases/deal/report-users-deals.use-case';
import { makeGetUsersDealsReport } from '../usecases/deal/get-users-deals-report';
import { ChannelsDealsResponseDTO } from 'domain/usecases/deal/report-channels-deals.use-case';
import { makeGetFunnelsDeals } from '../usecases/deal/get-funnels-deals-report';
import { makeGetChannelsDeals } from '../usecases/deal/get-channels-deals-report';
import { DealResult } from 'domain/entity/IDeal';

export type FilterProps = {
  startDate?: Date;
  endDate?: Date;
  userContext: 'my' | 'all';
  userId?: string;
};

export const makePanelPage = (): React.ReactElement => {
  const [filters, setFilters] = useState<FilterProps>({
    userContext: 'my',
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(),
  });

  const [newDeals, setNewDeals] = useState<NewDealsReportResponseDTO>();
  const [earnedDeals, setEarnedDeals] = useState<NewDealsReportResponseDTO>();
  const [lossedDeals, setLossedDeals] = useState<NewDealsReportResponseDTO>();
  const [funnelDealsReport, setFunnelDealsReport] = useState<
    FunnelsDealsResponseDTO[]
  >([]);
  const [lostReasons, setLostReasons] = useState<LostReasonsResponseDTO[]>([]);
  const [usersDeals, setUsersDeals] = useState<UsersDealsResponseDTO[]>([]);
  const [channelsDeal, setChannelsDeal] = useState<ChannelsDealsResponseDTO[]>(
    []
  );

  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const responseNews = await makeNewDealsReportUseCase().execute({
        result: DealResult.Open,
        ...filters,
      });

      const responseEarned = await makeNewDealsReportUseCase().execute({
        result: DealResult.Won,
        ...filters,
      });

      const responseLossed = await makeNewDealsReportUseCase().execute({
        result: DealResult.Lost,
        ...filters,
      });
      setNewDeals(responseNews);
      setEarnedDeals(responseEarned);
      setLossedDeals(responseLossed);

      const funnelDeals = await makeGetFunnelsDeals().execute(filters);

      setFunnelDealsReport(funnelDeals);

      const lostReasons = await makeGetLostReasonsReport().execute(filters);
      setLostReasons(lostReasons);

      const usersDeals = await makeGetUsersDealsReport().execute(filters);
      setUsersDeals(usersDeals);

      const channelsDeal = await makeGetChannelsDeals().execute({
        result: null,
        ...filters,
      });

      setChannelsDeal(channelsDeal);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [
    filters,
    setNewDeals,
    setEarnedDeals,
    setLossedDeals,
    setFunnelDealsReport,
    setLostReasons,
    setUsersDeals,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Panel
      fetchData={fetchData}
      earnedDeals={earnedDeals}
      lossedDeals={lossedDeals}
      newDeals={newDeals}
      loading={loading}
      funnelDealsReport={funnelDealsReport}
      setFilters={setFilters}
      filters={filters}
      lostReasons={lostReasons}
      usersDeals={usersDeals}
      channelsDeals={channelsDeal}
    />
  );
};
