import { useToken } from 'main/hooks/usetoken';

export const ensureAuthentication = (): boolean => {
  const { token } = useToken();

  if (token && token.length > 0) {
    return true;
  }

  return false;
};
