import React, { ReactElement, useState } from 'react';
import { Box, Button, Dialog, Table, Typography } from 'mino-ui';
import AddIcon from './icons/add.svg';
import { ManageDrawer } from './ManageDrawer';
import { useGroups } from 'main/hooks/useGroups';
import { makeDeleteGroupUseCase } from 'main/factories/usecases/group/delete-group-factory';
import { pressEscKey } from 'main/helpers/scape-key-esc';
import { SaveRoleRequest } from 'domain/usecases/role/save-role-use-case';
import { IRole } from 'domain/entity/IRole';

const GroupsConfig = (): ReactElement => {
  const [manageOpen, setManageOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updatePayload, setUpdatePayload] = useState<SaveRoleRequest>();

  const { groups, fetchGroups } = useGroups();

  async function deleteGroup(userId: string) {
    try {
      setDeleteLoading(true);
      await makeDeleteGroupUseCase(userId).execute();
      fetchGroups();
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteLoading(false);
    }
  }

  return (
    <Box flexDirection="column" gap={16}>
      <Box gap={16}>
        <Button
          icon={AddIcon}
          onClick={() => {
            setManageOpen(true);
            setUpdatePayload(undefined);
          }}
          text="Adicionar"
        />
      </Box>
      <Table
        hasOutline
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            width: 100,
          },
          {
            field: 'actions',
            headerName: '',
          },
        ]}
        rows={groups.map((g: IRole) => ({
          id: g.id as string,
          name: g.name,
          actions: (
            <Box gap={8}>
              <Button
                size="small"
                variant="ghost"
                text="Editar"
                onClick={() => {
                  setUpdatePayload({
                    id: g.id,
                    name: g.name,
                    permissions: g.permissions,
                  });
                  setManageOpen(true);
                }}
              />
              <Dialog
                modal
                title="Atenção!"
                trigger={
                  <Button size="small" variant="secondary" text="Excluir" />
                }
                content={
                  <Box flexDirection="column" gap={32}>
                    <Typography noWrap variant="body">
                      Tem certeza que deseja excluir esse grupo?
                    </Typography>

                    <Box gap={8}>
                      <Button
                        disabled={deleteLoading}
                        onClick={() => deleteGroup(g.id || '')}
                        variant="primary"
                        text={deleteLoading ? 'Removendo...' : 'Confirmar'}
                      />
                      <Button
                        onClick={() => pressEscKey()}
                        variant="outline"
                        text="Cancelar"
                      />
                    </Box>
                  </Box>
                }
              />
            </Box>
          ),
        }))}
      />
      <ManageDrawer
        updatePayload={updatePayload}
        callback={() => {
          fetchGroups();
          setUpdatePayload(undefined);
          setManageOpen(false);
        }}
        setIsOpen={setManageOpen}
        isOpen={manageOpen}
      />
    </Box>
  );
};

export default GroupsConfig;
