import { RemoteListActivities } from 'data/usecases';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export type ActivityContext = 'company' | 'contact' | 'deal' | 'all';

export type ListActivitiesParams = {
  type: ActivityContext;
  dealId?: string;
  contactId?: string;
  companyId?: string;
};

export const makeListActivities = (): RemoteListActivities => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteListActivities(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/activities`,
    toastify
  );
};
