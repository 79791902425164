import { RemoteLoginUseCase } from 'data/usecases/remote-login';
import { LoginUseCase } from 'domain/usecases/session/login-use-case';
import { makeAxiosHttpClient } from '../http';
import { makeToastify } from 'main/factories/notification/ToastifyFactory';

export const makeLoginUseCase = (): LoginUseCase => {
  const toastify = makeToastify();

  return new RemoteLoginUseCase(
    makeAxiosHttpClient(),
    `${process.env.REACT_APP_API_BASE_URL}/auth`,
    toastify
  );
};
