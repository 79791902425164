import { RemoteListDeals } from 'data/usecases';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';
import { useToken } from 'main/hooks/usetoken';

export const makeListDealsUseCase = (): RemoteListDeals => {
  const { token = '' } = useToken();
  const axios = new AxiosProvider({
    token,
  });
  const toastify = new ToastifyProvier();

  return new RemoteListDeals(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/deals`,
    toastify
  );
};
