import { ActivityType } from 'domain/entity/IActivity';
import { CreateActivityRequest } from 'domain/usecases/activity/create-activities-use-case';
import { createContext } from 'react';

export type PhoneNumbersModel = {
  phone: string;
  name: string;
  entity: 'contact' | 'company';
};

export type EmailsModel = {
  email: string;
  name: string;
  entity: 'contact' | 'company';
};

export type ActivityContextProps = {
  handleSubmit: (
    payload: CreateActivityRequest,
    preventToast?: boolean
  ) => Promise<void>;
  load: boolean;
  setLoad: (load: boolean) => void;
  emails?: EmailsModel[];
  phoneNumbers?: PhoneNumbersModel[];
  contact_id?: string;
  openSchedule: boolean;
  setOpenSchedule: (open: boolean) => void;
  context: 'deal' | 'contact' | 'company';
  contextId: string;
  scheduleActivityType: ActivityType;
  setScheduleActivityType: (type: ActivityType) => void;
  files?: FileList;
  setFiles: (files?: FileList) => void;
};

export const ActivityContext = createContext({} as ActivityContextProps);
