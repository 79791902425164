import React, { ReactElement, useContext } from 'react';
import { Avatar, Badge, Box, Icon, PopOver } from 'mino-ui';
import { useDrag } from 'react-dnd';
import * as S from './styles';
import { Link, useNavigate } from 'react-router-dom';
import { IDeal } from 'domain/entity/IDeal';
import PipeDealContext from 'presentation/pages/Deal/contexts/PipeDealContext';
import { pressEscKey } from 'main/helpers/scape-key-esc';
import { formatToReal } from 'main/helpers/format-to-real';
import { IconColors } from '../../..';

interface CardProps {
  deal: IDeal;
}

const PipeCard = (props: CardProps): ReactElement => {
  const { deal } = props;

  const { setOpenChangeResult } = useContext(PipeDealContext);

  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: 'CARD',
    item: deal,

    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  function findColorByDealResult(result?: boolean | number): string {
    switch (result) {
      case 1:
        return 'won';
      case 2:
        return 'loss';
      default:
        return 'open';
    }
  }

  return (
    <S.PipeCard
      color={findColorByDealResult(deal.result)}
      className={isDragging ? 'dragging' : ''}
      ref={dragRef}
      key={deal.id}
    >
      <S.CardTitle>
        <Link to={`/deals/${deal.id}`}>{deal.name}</Link>

        <PopOver
          side="top"
          align="start"
          trigger={<Icon iconName="MoreVertical20Regular" />}
          content={
            <S.IconGroup>
              <S.Iconwrapper
                type="sell"
                onClick={() => {
                  setOpenChangeResult({
                    open: true,
                    type: 'sell',
                    dealId: deal.id,
                  });
                }}
              >
                <Icon
                  iconName="ThumbLike20Regular"
                  color={IconColors.systemGreen}
                />
              </S.Iconwrapper>
              <S.Iconwrapper
                type="loss"
                onClick={() => {
                  setOpenChangeResult({
                    open: true,
                    type: 'loss',
                    dealId: deal.id,
                  });
                }}
              >
                <Icon
                  iconName="ThumbDislike20Regular"
                  color={IconColors.systemRed}
                />
              </S.Iconwrapper>
            </S.IconGroup>
          }
        />
      </S.CardTitle>
      {deal.contacts && deal.contacts.length > 0 && (
        <S.CardContact>
          <Box gap={8} alignItems="center">
            {deal.contacts?.map(
              (contact, index) =>
                index === 0 && (
                  <>
                    <Avatar width={20} name={contact.name} />
                    <Link key={contact.id} to={`/contacts/${contact.id}`}>
                      {contact.name}
                    </Link>
                  </>
                )
            )}
            {deal.contacts && deal.contacts.length > 1 && (
              <Badge
                value={deal.contacts ? `+${deal.contacts?.length - 1}` : 0}
              />
            )}
          </Box>
        </S.CardContact>
      )}
      <S.CardUser>
        {deal.user ? (
          <Avatar
            width={20}
            name={deal?.user?.name}
            image={deal?.user?.avatar || ''}
          />
        ) : (
          <Avatar width={20} name={'N '} />
        )}

        <p>{deal?.user?.name || 'Sem proprietário'}</p>
      </S.CardUser>
      <S.CardValue>
        <Icon iconName="ShoppingBag20Regular" color={IconColors.blue} />
        <span>{formatToReal(deal.value ? deal.value : 0)}</span>
      </S.CardValue>
    </S.PipeCard>
  );
};

export default PipeCard;
