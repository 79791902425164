import { makeActivityPage } from 'main/factories/pages/ActivityPageFactory';
import { makeContactListPage } from 'main/factories/pages/ContactListPageFactory';
import { makeSignUpPage } from 'main/factories/pages/SigUpPageFactory';
import { makeSingleDealPage } from 'main/factories/pages/SingleDealPageFactory';
import { makeSingleContactPage } from 'main/factories/pages/ContactSinglePageFactory';
import { makeCompaniesListPage } from 'main/factories/pages/CompanyListPageFactory';
import { makeSingleCompanyPage } from 'main/factories/pages/SingleCompanyPageFactory';
import { SingleIntegrationPage } from 'presentation/pages/Config/Integrations/single/component';
import { ProfileView } from 'presentation/pages/Profile/component';
import ConfigPage from 'presentation/pages/Config';
import { makePanelPage } from 'main/factories/pages/PanelPageFactory';
import {
  makeDealPage,
  makeHomePage,
  makeLoginPage,
  makeNotFoundPage,
} from '../factories/pages';
import { RdCallBackPage } from 'presentation/pages/Config/Integrations/components/integrate/rd/RdCallback';
import ForgotPasswordPage from 'presentation/pages/ForgotPassword';
import { GoogleCallback } from 'presentation/pages/Activity/components/Google/GoogleCallback';
import { makeInvitationPage } from 'main/factories/pages/makeInvitationPage';
import Chat from 'presentation/pages/Chat';

export interface IRoute {
  path: string;
  name: string;
  component: any;
  private?: boolean;
  restricted?: boolean;
}

const routes: IRoute[] = [
  {
    path: '/login',
    name: 'Login',
    component: makeLoginPage,
    private: false,
    restricted: true,
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: makeSignUpPage,
    private: false,
    restricted: true,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPasswordPage,
    private: false,
    restricted: true,
  },
  {
    path: '*',
    name: 'Not Found',
    component: makeNotFoundPage,
    private: true,
  },
  {
    path: '/',
    name: 'Home',
    component: makeHomePage,
    private: true,
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: makeContactListPage,
    private: true,
  },
  {
    path: '/companies',
    name: 'Companies',
    component: makeCompaniesListPage,
    private: true,
  },
  {
    path: '/companies/:id',
    name: 'Company',
    component: makeSingleCompanyPage,
    private: true,
  },
  {
    path: '/contacts/:id',
    name: 'Contact',
    component: makeSingleContactPage,
    private: true,
  },
  {
    path: '/deals',
    name: 'Deals',
    component: makeDealPage,
    private: true,
  },
  {
    path: '/deals/:id',
    name: 'Deal',
    component: makeSingleDealPage,
    private: true,
  },
  {
    path: '/activities',
    name: 'Activities',
    component: makeActivityPage,
    private: true,
  },
  {
    path: '/config',
    name: 'Configuration',
    component: ConfigPage,
    private: true,
  },
  {
    path: '/integrations/:id',
    name: 'Integrations',
    component: SingleIntegrationPage,
    private: true,
  },
  {
    path: '/profile/',
    name: 'Profile',
    component: ProfileView,
    private: true,
  },

  {
    path: '/panel',
    name: 'Panel',
    component: makePanelPage,
    private: true,
  },
  {
    path: '/rd/callback',
    name: 'Callback',
    component: RdCallBackPage,
    private: true,
  },
  {
    path: '/google/calendar/callback',
    name: 'Google Callback',
    component: GoogleCallback,
    private: true,
  },
  {
    path: '/invitation',
    name: 'invitation',
    component: makeInvitationPage,
    private: false,
    restricted: true,
  },
  {
    path: '/chat',
    name: 'Chat',
    component: Chat,
    private: true,
  },
];

export default routes;
