import { RemoteSendCustomMailUseCase } from 'data/usecases';
import { SendCustomMailUseCase } from 'domain/usecases/mail/send-mail-custom-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';
import { useToken } from 'main/hooks/usetoken';

export const makeSendCustomMail = (): SendCustomMailUseCase => {
  const { tenant = '', token = '' } = useToken();
  const axios = new AxiosProvider({
    tenant,
    token,
  });

  const toastify = new ToastifyProvier();

  return new RemoteSendCustomMailUseCase(
    `${process.env.REACT_APP_API_BASE_URL}/mail`,
    axios,
    toastify
  );
};
