import React, { ReactElement, useState } from 'react';
import { Box, Button, Dialog, Input, Typography } from 'mino-ui';
import { useForm } from 'react-hook-form';
import DownloadIcon from './icons/downloadIcon.svg';
import { makeImportContactsUseCase } from 'main/factories/usecases/contact/import-contacts-factory';
import { pressEscKey } from 'main/helpers/scape-key-esc';

interface ImportForm {
  file: File;
}

interface TriggerProps {
  trigger?: ReactElement;
  isDialogOpen: boolean;
  setDialogOpen: (value: boolean) => void;
  callback: () => void;
}

export const ImportContacts = (props: TriggerProps): ReactElement => {
  const { trigger, isDialogOpen, setDialogOpen, callback } = props;
  const { register, handleSubmit } = useForm<ImportForm>();
  const [loading, setLoading] = useState(false);

  async function handleImportSubmit(data: ImportForm): Promise<void> {
    try {
      setLoading(true);
      await makeImportContactsUseCase().execute({
        file: data.file[0],
      });
      callback();

      setTimeout(() => {
        pressEscKey();
      }, 500);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function handleDownloadFromUrl(): void {
    window.open(
      'https://taskflowstorage.blob.core.windows.net/taskflowcrm/modelo-contacts.xlsx',
      '_blank'
    );
  }

  return (
    <Dialog
      onSubmit={handleSubmit(handleImportSubmit)}
      loading={loading}
      title="Importar Contatos"
      open={isDialogOpen}
      onOpenChange={() => setDialogOpen(false)}
      trigger={trigger}
      content={
        <Box flexDirection="column" gap={24}>
          <Box flexDirection="column" gap={16}>
            <Box flexDirection="column" gap={16}>
              <Typography variant="body" fontSize={12} color="gray" noWrap>
                1. Para importar contatos, você precisa baixar o modelo de
                importação. Com esse modelo você consegue importar Contatos e
                Empresas ao mesmo tempo.
                <br />
                <br />
                <strong>
                  Só é possível importar até 10.000 contatos por vez.
                </strong>
              </Typography>
              <Button
                onClick={handleDownloadFromUrl}
                size="medium"
                text="Baixar modelo"
                variant="ghost"
                icon={DownloadIcon}
              />
            </Box>

            <form onSubmit={handleSubmit(handleImportSubmit)}>
              <Box gap={16} flexDirection="column">
                <Typography variant="body" fontSize={12} color="gray" noWrap>
                  2. Faça o upload do seu arquivo com as as colunas devidamente
                  preenchidas. A importação será salva no quadro de importações
                  com o nome do arquivo.
                </Typography>
                <Input
                  name="file"
                  type="file"
                  register={register}
                  validations={{
                    required: true,
                  }}
                />
                <Typography variant="body" fontSize={12} color="gray" noWrap>
                  3. Agora clique em Importar e confira se os dados estão
                  corretos.
                </Typography>
              </Box>
            </form>
          </Box>
        </Box>
      }
    />
  );
};
