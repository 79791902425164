import { RemoteRegisterUseCase } from 'data/usecases/sessions/remote-register-session';
import { RegisterUseCase } from 'domain/usecases/session/register-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeRegisterSessionUseCase = (): RegisterUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteRegisterUseCase(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/session/register`,
    toastify
  );
};
