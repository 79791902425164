import { RemoteBulkActionsDeals } from 'data/usecases/deal/remote-bulk-actions-deas';
import { BulkActionsDealsUseCase } from 'domain/usecases/deal/bulk-actions-deals-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';
import { useToken } from 'main/hooks/usetoken';

export const makeBulkActionsDeals = (): BulkActionsDealsUseCase => {
  const { tenant = '', token = '' } = useToken();
  const axios = new AxiosProvider({ tenant, token });
  const toastify = new ToastifyProvier();

  return new RemoteBulkActionsDeals(
    `${process.env.REACT_APP_API_BASE_URL}/deals/bulk`,
    axios,
    toastify
  );
};
