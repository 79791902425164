import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Input,
  Table,
  Typography,
  Dialog,
  Dropdown,
} from 'mino-ui';
import AddIcon from './icons/add.svg';
import { IService } from 'domain/entity/IService';
import { makeListServicesUseCase } from 'main/factories/usecases';
import { ManageDrawer } from './ManageDrawer';
import { pressEscKey } from 'main/helpers/scape-key-esc';
import { RequestPayloadService } from 'domain/usecases/service/save-services-use-case';
import { makeDeleteServiceUseCase } from 'main/factories/usecases/service/delete-service-factory';
import { makeSaveServiceUseCase } from 'main/factories/usecases/service/save-service-factory';
import { useForm } from 'react-hook-form';
import {
  moneyMask,
  formatPriceForBackend,
} from 'main/helpers/input-mask-money';
import { formatToReal } from 'main/helpers';

const ServicesConfig = (): ReactElement => {
  const [manageOpen, setManageOpen] = useState(false);
  const [updatePayload, setUpdatePayload] = useState<RequestPayloadService>();
  const [deleteListing, setDeleteListing] = useState(false);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const [services, setServices] = useState<IService[]>([]);
  const [createLoading, setCreateLoading] = useState(false);

  const [deleteProductDialog, setDeleteProductDialog] = useState({
    open: false,
    id: '',
  });

  async function saveNewService(data: RequestPayloadService) {
    const { price } = data;

    try {
      await makeSaveServiceUseCase().execute(
        {
          ...data,
          price: price && formatPriceForBackend(`${price}`),
        },
        'create'
      );
      fetchServices();
      reset();
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteService(serviceId: string) {
    try {
      setDeleteListing(true);
      await makeDeleteServiceUseCase(serviceId).execute();

      fetchServices();

      pressEscKey();
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteListing(false);
    }
  }

  const fetchServices = useCallback(async () => {
    try {
      setCreateLoading(true);
      const {
        data,
        total,
        page: activePage,
      } = await makeListServicesUseCase().execute({
        page: page,
      });

      setServices(data);
      setPage(activePage);
      setTotal(total);
    } catch (error) {
      console.log(error);
    } finally {
      setCreateLoading(false);
    }
  }, [page]);

  function handlePageChange(pageNumber: number) {
    setPage(pageNumber);
  }

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    reset,
    watch,
  } = useForm<RequestPayloadService>();

  const inputPrice = watch('price');

  useEffect(() => {
    if (inputPrice) {
      setValue('price', moneyMask(`${inputPrice}`));
    }
  }, [inputPrice, setValue]);

  return (
    <Box flexDirection="column" gap={16}>
      <Box gap={16}>
        <form onSubmit={handleSubmit(saveNewService)}>
          <Box gap={8}>
            <Input
              autoComplete="off"
              placeholder="Novo produto"
              name="name"
              register={register}
              errors={errors}
              validations={{
                minLength: 3,
                maxLength: 42,
                required: true,
              }}
            />
            <Input
              autoComplete="off"
              placeholder="Ex:100,00"
              name="price"
              register={register}
              errors={errors}
            />

            <Button
              disabled={createLoading}
              icon={AddIcon}
              text="Adicionar"
              type="submit"
            />
          </Box>
        </form>
      </Box>

      <Table
        compact
        hasOutline
        pageRangeDisplayed={5}
        activePage={page}
        itemsCountPerPage={25}
        onChangePage={handlePageChange}
        totalItemsCount={total}
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            width: 60,
            sortable: true,
          },
          {
            field: 'price',
            headerName: 'Preço',
            width: 30,
          },
          {
            field: 'actions',
            headerName: '',
          },
        ]}
        rows={services.map((s) => ({
          id: s.id,
          name: s.name,
          price: formatToReal(s.price ? s.price : 0),
          actions: (
            <Dropdown
              align="end"
              trigger={'action-button'}
              onChange={(value) => {
                if (value === 'edit') {
                  setUpdatePayload({
                    id: s.id,
                    name: s.name,
                    price: s.price,
                  });
                  setManageOpen(true);
                } else if (value === 'delete') {
                  setDeleteProductDialog({
                    open: true,
                    id: s.id,
                  });
                }
              }}
              items={[
                {
                  label: 'Editar',
                  value: 'edit',
                },
                {
                  label: 'Excluir',
                  value: 'delete',
                },
              ]}
            />
          ),
        }))}
      />

      <ManageDrawer
        updatePayload={updatePayload}
        isOpen={manageOpen}
        setIsOpen={setManageOpen}
        callback={() => {
          fetchServices();
          setUpdatePayload(undefined);
        }}
      />

      <Dialog
        open={deleteProductDialog.open}
        onOpenChange={(v) =>
          setDeleteProductDialog({ ...deleteProductDialog, open: v })
        }
        title="Atenção!"
        trigger={<></>}
        content={
          <Box flexDirection="column" gap={32}>
            <Typography noWrap variant="body">
              Tem certeza que deseja excluir esse produto?
            </Typography>

            <Box gap={8}>
              <Button
                disabled={deleteListing}
                onClick={() => deleteService(deleteProductDialog.id)}
                variant="primary"
                text={deleteListing ? 'Removendo...' : 'Confirmar'}
              />
              <Button
                onClick={() => {
                  pressEscKey();
                  setDeleteProductDialog({
                    id: '',
                    open: false,
                  });
                }}
                variant="outline"
                text="Cancelar"
              />
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

export default ServicesConfig;
