import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { ActivityStatus, IActivity } from 'domain/entity/IActivity';
import { Box, Button, ToggleGroup } from 'mino-ui';
import * as S from './styles';
import CalendarView from './components/calendar/calendar';
import ListActivityCard from './components/ListActivityCard/component';
import TaskCard from './components/TaskCard/component';
import DrawerFilterActivity from './filterActivity';
import { ActivityFilterParams } from 'domain/usecases/activity/list-activities-use-case';
import { makeListActivities } from 'main/factories/usecases';
import ActivityListContext from './context/ActivityListContext';
import EmptyStateActivity from './components/EmptyState/component';
import NotFoundStateActivity from './components/NotFoundState/component';
import GoogleAuthButton from './components/Google/GoogleButton';
import { SessionContext } from '../../layout/contexts/SessionContext';
import { useGoogleEvents } from './components/Google/hooks/useGoogleEvents';
import ScheduleActivityDialog, {
  ScheduleActivityForm,
} from 'presentation/shared/templates/Activitites/dialogs/ScheduleActivityDialog';

interface ActivityPageProps {
  activities: IActivity[];
  loading: boolean;
  fetchData: (params: ActivityFilterParams) => void;
  page: number;
  lastPage: number;
  setPage: (number) => void;
  setActivities: (params: IActivity[]) => void;
}
export enum IconColors {
  blue = 'blue',
  white = 'white',
  dark = 'dark',
  systemRed = 'systemRed',
}
export enum IconNames {
  CalendarLtr20Regular = 'CalendarLtr20Regular',
  Home20Regular = 'Home20Regular',
}
const ActivityPage = (props: ActivityPageProps): ReactElement => {
  const {
    activities: initialActivities,
    fetchData,
    lastPage,
    page,
    setPage,
    loading,
  } = props;

  const {
    session: {
      id: userId,
      integrations: { google } = {
        google: { active: false },
      },
    },
  } = useContext(SessionContext);

  const [activities, setActivities] = useState<IActivity[]>([]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [view, setView] = useState<'calendar' | 'list'>('list');
  const [initialStartDate, setInitialStartDate] = useState<Date>();
  const [initialEndDate, setInitialEndDate] = useState<Date>();

  const [activityToUpdate, setActivityToUpdate] =
    useState<ScheduleActivityForm>();

  const initialFilter = {
    page: 1,
    type: undefined,
    status: ActivityStatus.SCHEDULED,
    user_id: userId,
  };

  const [filter, setFilter] = useState<ActivityFilterParams>(initialFilter);

  const [filterOpen, setFilterOpen] = useState(false);

  async function handleMoreButton(): Promise<void> {
    if (activities) {
      const { data: newActivities, page: dataPage } =
        await makeListActivities().execute({
          page: page + 1,
        });

      setActivities([...activities, ...newActivities]);
      setPage(dataPage);
    }
  }
  const { events: googleEvents } = useGoogleEvents();

  useEffect(() => {
    if (google.active && googleEvents.length > 0) {
      const allData = [...initialActivities, ...googleEvents];

      const sortedData = allData.sort((a, b) => {
        const dateA = new Date(a.start);
        const dateB = new Date(b.start);

        return dateA.getTime() - dateB.getTime();
      });

      setActivities(sortedData);
    } else {
      setActivities(initialActivities);
    }
  }, [google.active, googleEvents, initialActivities]);

  useEffect(() => {
    fetchData(filter);
  }, [fetchData, filter]);

  return (
    <ActivityListContext.Provider
      value={{
        activityToUpdate,
        setActivityToUpdate,
        filter,
        setFilter,
        filterOpen,
        setFilterOpen,
        fetchData,
        isDrawerOpen,
        setIsDrawerOpen,
        initialStartDate,
        setInitialStartDate,
        initialEndDate,
        setInitialEndDate,
      }}
    >
      <S.Toolbar>
        <S.ToolbarLeftContent>
          <Button
            variant="primary"
            text="Tarefa"
            type="button"
            icon="AddCircle20Regular"
            iconColor={IconColors.white}
            hiddenTextOnMobile
            onClick={() => setIsDrawerOpen(true)}
          />
          {process.env.NODE_ENV === 'development' ? (
            <GoogleAuthButton size="medium" active={google && !google.active} />
          ) : null}
          <S.Separator />
          <ToggleGroup
            defaultValue={view}
            type="single"
            value={view}
            size="large"
            onValueChange={(value) => {
              if (value == 'list') setView('list');
              if (value == 'calendar') setView('calendar');
            }}
            items={[
              {
                icon: 'TasksApp20Regular',
                value: 'list',
              },
              {
                icon: 'CalendarLtr20Regular',
                value: 'calendar',
              },
            ]}
          />
        </S.ToolbarLeftContent>
        <S.ToolbarRightContent>
          <Button
            text="Filtros"
            hiddenTextOnMobile
            icon="Filter20Filled"
            iconColor={IconColors.blue}
            variant="ghost"
            type="button"
            onClick={() => setFilterOpen(true)}
          />
        </S.ToolbarRightContent>
      </S.Toolbar>
      <Box gap={16} boxMain>
        {view === 'list' ? (
          <>
            {JSON.stringify(initialFilter) === JSON.stringify(filter) &&
            activities.length === 0 &&
            !loading ? (
              <EmptyStateActivity />
            ) : JSON.stringify(initialFilter) !== JSON.stringify(filter) &&
              activities.length === 0 &&
              !loading ? (
              <NotFoundStateActivity />
            ) : (
              <S.GridArea>
                <S.MainContent>
                  <S.ListCardsWrap>
                    {activities.map((a) => (
                      <ListActivityCard
                        key={a.id}
                        activity={a}
                        onCheck={() => undefined}
                        callback={() => fetchData(filter)}
                      />
                    ))}
                  </S.ListCardsWrap>
                  {lastPage > page && (
                    <Box justifyContent="center" style={{ padding: '16px' }}>
                      <Button
                        onClick={handleMoreButton}
                        text="carregar mais"
                        variant="ghost"
                      />
                    </Box>
                  )}
                </S.MainContent>
                <S.RightContent>
                  <S.TaskCardsWrap>
                    <TaskCard
                      activities={activities}
                      setPage={setPage}
                      page={page}
                      lastPage={lastPage}
                      setActivities={setActivities}
                    />
                  </S.TaskCardsWrap>
                </S.RightContent>
              </S.GridArea>
            )}
          </>
        ) : (
          <CalendarView activities={activities} />
        )}
      </Box>

      <DrawerFilterActivity
        fetchData={fetchData}
        isOpen={filterOpen}
        setIsOpen={setFilterOpen}
      />

      <ScheduleActivityDialog
        key={activityToUpdate?.id}
        trigger={<></>}
        open={isDrawerOpen}
        setOpen={(state) => {
          setIsDrawerOpen(state);
          if (!state) {
            setActivityToUpdate(undefined);
          }
        }}
        initialStart={initialStartDate}
        initialEnd={initialEndDate}
        activity={activityToUpdate}
        callback={() => {
          fetchData(filter);
          setIsDrawerOpen(false);
        }}
      />
    </ActivityListContext.Provider>
  );
};

export default ActivityPage;
