import { RemoteListMessages } from 'data/usecases/mail/remote-list-messages';
import { ListMessagesUseCase } from 'domain/usecases/mail/list-messages-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeListMessages = (): ListMessagesUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteListMessages(
    axios,
    'https://api.nylas.com/messages',
    toastify
  );
};
