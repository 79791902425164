import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { ISession } from 'domain/entity/ISession';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import { SessionUseCase } from 'domain/usecases/session/session-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteGetSessionUseCase implements SessionUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(): Promise<ISession> {
    const response = await this.httpClient.request({
      url: this.url,
      method: 'get',
    });

    const sessionDate = response.body as ISession;

    const { logout } = useToken();

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return sessionDate as ISession;

      case HttpStatusCode.unauthorized:
        this.toastService.show('Não autorizado!', {
          type: 'error',
        });
        throw new NotAuthorizedError('Não autorizado!');

      default:
        this.toastService.show(
          'Algo deu errado ao obter sua sessão. Entre em contato com o suporte.',
          {
            type: 'error',
          }
        );

        setTimeout(() => {
          localStorage.clear();
          logout();
        }, 5000);

        throw new UnexpectedError('Algo deu errado ao obter sua sessão.');
    }
  }
}
