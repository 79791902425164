import { ActivityType } from 'domain/entity/IActivity';
import System from './icons/system.svg';

export function renderIcon(activity_type: ActivityType): string {
  switch (activity_type) {
    case ActivityType.CALL:
      return `Call20Regular`;
    case ActivityType.EMAIL:
      return `Mail20Regular`;
    case ActivityType.MEETING:
      return `BuildingPeople20Regular`;
    case ActivityType.PROPOSAL:
      return `Handshake20Regular`;
    case ActivityType.MESSAGE:
      return `Chat20Regular`;
    case ActivityType.NOTE:
      return `Notepad20Regular`;
    case ActivityType.SALE:
      return `ThumbLike20Regular`;
    case ActivityType.LOSS:
      return `ThumbDislike20Regular`;

    default:
      return `${System}`;
  }
}
