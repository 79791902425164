import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { IDeal } from 'domain/entity/IDeal';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import {
  UpdateDealRequest,
  UpdateDealUseCase,
} from 'domain/usecases/deal/update-deal-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteUpdateDeal implements UpdateDealUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(params: UpdateDealRequest): Promise<IDeal> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      method: 'patch',
      url: this.url,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Tenant: tenant,
      },
      body: params,
    });

    const deal = response.body;

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        this.toastService.show('Atualizado com sucesso!', {
          type: 'success',
        });
        return {
          id: deal.id,
          name: deal.name,
          tenant_id: deal.tenant_id,
          funnel_id: deal.funnel_id,
          created_at: deal.created_at,
          updated_at: deal.updated_at,
          value: deal.value,
          contacts: deal.contacts,
          companies: deal.companies,
          result: deal.result,
          user_id: deal.user_id,
          user: deal.user,
          payment: deal.payment,
        };

      case HttpStatusCode.unauthorized:
        this.toastService.show('Não autorizado', {
          type: 'error',
        });
        throw new NotAuthorizedError('Unauthorized');
      default:
        this.toastService.show('Erro no servidor', {
          type: 'error',
        });
        throw new UnexpectedError('Unexpected error');
    }
  }
}
