import React, { ReactElement, useContext, useState } from 'react';
import { Box, Table, Avatar, Typography, Badge } from 'mino-ui';
import DealContext from '../contexts/DealContext';
import { Link } from 'react-router-dom';
import { formatToReal, formatBrazilianDate } from 'main/helpers';
import BulkOwnerDeals from './components/bulkOwner/component';
import BulkFunnels from './components/bulkFunnels/component';
import BulkTagsDeals from './components/bulkTags/component';
import BulkWon from './components/bulkWon/component';
import BulkLoss from './components/bulkLoss/component';

export enum IconColors {
  dark = 'dark',
  white = 'white',
  green = 'green',
  red = 'red',
}

const DealsList = (): ReactElement => {
  const {
    filter,
    deals,
    activePage,
    totalDeals,
    setFilter,
    setBulkOwner,
    setBulkTags,
    setBulkFunnel,
    setBulkLoss,
    setBulkWon,
  } = useContext(DealContext);

  function handlePageChange(pageNumber: number) {
    setFilter({ ...filter, page: pageNumber });
  }

  const [ids, setIds] = useState<string[]>([]);

  return (
    <div>
      <Table
        hasBulkActions
        bulkActions={[
          {
            label: 'Alterar Responsável',
            action: (ids) => {
              setBulkOwner(true);
              setIds(ids);
            },
            buttonVariant: 'ghost',
            iconName: 'PersonSync20Regular',
            iconColor: IconColors.dark,
          },
          {
            label: 'Alterar Tags',
            action: (ids) => {
              setBulkTags(true);
              setIds(ids);
            },
            buttonVariant: 'ghost',
            iconName: 'Tag',
            iconColor: IconColors.dark,
          },
          {
            label: 'Alterar Etapa',
            action: (ids) => {
              setBulkFunnel(true);
              setIds(ids);
            },
            buttonVariant: 'ghostSecondary',
            iconName: 'ColumnTriple20Regular',
            iconColor: IconColors.dark,
          },
          {
            label: 'Marcar Venda',
            action: (ids) => {
              setBulkWon(true);
              setIds(ids);
            },
            buttonVariant: 'ghost',
            iconName: 'ThumbLike20Regular',
            iconColor: IconColors.green,
          },
          {
            label: 'Marcar Perda',
            action: (ids) => {
              setBulkLoss(true);
              setIds(ids);
            },
            buttonVariant: 'ghost',
            iconName: 'ThumbDislike20Regular',
            iconColor: IconColors.red,
          },
        ]}
        itemsCountPerPage={15}
        activePage={activePage}
        totalItemsCount={totalDeals}
        onChangePage={handlePageChange}
        onSort={(sort) => {
          setFilter({
            ...filter,
            sort_column: sort.field,
            sort_direction: sort.order,
          });
        }}
        columns={[
          {
            field: 'name',
            headerName: 'Negócio',
            align: 'left',
            sortable: true,
          },
          {
            field: 'contact',
            headerName: 'Contato',
            align: 'left',
            hiddenOnMobile: true,
          },
          {
            field: 'lastUpdate',
            headerName: 'Ultima atualização',
            align: 'left',
            hiddenOnMobile: true,
          },
          {
            field: 'funnel',
            headerName: 'Etapa',
            align: 'left',
            hiddenOnMobile: true,
          },
          {
            field: 'value',
            headerName: 'Valor',
            align: 'left',
            hiddenOnMobile: true,
          },
        ]}
        rows={deals?.map((d) => ({
          id: d.id,
          name: (
            <Box alignItems="center">
              <Link to={`/deals/${d.id}`}>
                <Typography weight="bold" variant="body" color="primary">
                  {d.name}
                </Typography>
              </Link>
            </Box>
          ),
          contact:
            d?.contacts?.map(
              (contact, index) =>
                index === 0 && (
                  <Box key={contact.id} gap={8} alignItems="center">
                    <Avatar name={contact.name} />
                    <Box gap={8}>
                      <Link to={`/contacts/${contact.id}`}>
                        <Typography color="primary" variant="body">
                          {contact.name}
                        </Typography>
                      </Link>

                      {d.contacts && d.contacts.length > 1 && (
                        <Badge
                          value={d.contacts ? `+${d?.contacts?.length - 1}` : 0}
                        />
                      )}
                    </Box>
                  </Box>
                )
            ) ?? null,
          lastUpdate: formatBrazilianDate({
            date: new Date(d.updated_at),
            timeAndDate: true,
          }),
          funnel: d.funnel?.name,
          value: d.value ? formatToReal(d.value) : '',
        }))}
      />
      <BulkOwnerDeals ids={ids} />
      <BulkFunnels ids={ids} />
      <BulkTagsDeals ids={ids} />
      <BulkWon ids={ids} />
      <BulkLoss ids={ids} />
    </div>
  );
};

export default DealsList;
