import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #f9f9f9;
`;

export const LeftContainer = styled.div`
  width: 300px;
  border-right: 1px solid #ddd;
  background-color: white;
  height: 100%;
  padding: 1rem;
  overflow-y: auto;
`;

export const RightContainer = styled.div`
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
