import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { IActivity } from 'domain/entity/IActivity';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import {
  CreateActivityRequest,
  SaveActivityUseCase,
} from 'domain/usecases/activity/create-activities-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteSaveActivityUseCase implements SaveActivityUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService?: ToastService
  ) {}

  async execute(
    payload: CreateActivityRequest,
    type?: 'create' | 'update'
  ): Promise<IActivity> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      method: type === 'update' ? 'patch' : 'post',
      url: this.url,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Tenant: tenant,
      },
      body: payload,
    });

    const activity = response.body;

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        this.toastService?.show(
          type === 'create' ? 'Criado com sucesso!' : 'Atualizado com sucesso!',
          {
            type: 'success',
          }
        );

        return {
          id: activity.id,
          title: activity.title,
          type: activity.type,
          user_id: activity.user_id,
          status: activity.status,
          start: activity.start,
          end: activity.end,
          allDay: activity.end,
          description: activity.description,
          deal_id: activity.deal_id,
          contact_id: activity.contact_id,
          company_id: activity.company_id,
          created_at: activity.created_at,
          updated_at: activity.updated_at,
        };

      case HttpStatusCode.unauthorized:
        this.toastService?.show('Não autorizado', {
          type: 'error',
        });
        throw new NotAuthorizedError('Unauthorized');
      default:
        this.toastService?.show('Erro no servidor', {
          type: 'error',
        });
        throw new UnexpectedError('Unexpected error');
    }
  }
}
