import { HttpClient } from 'data/protocols/http/HttpClient';
import {
  SaveRoleRequest,
  SaveRoleUseCase,
} from 'domain/usecases/role/save-role-use-case';

export class RemoteSaveRoleUseCase implements SaveRoleUseCase {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}

  async execute(data: SaveRoleRequest): Promise<void> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: this.url.includes('-') ? 'put' : 'post',
      body: data,
    });

    if (httpResponse.statusCode !== 200) {
      throw new Error('Unexpected error');
    }

    return httpResponse.body;
  }
}
