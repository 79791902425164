import {
  HttpRequest,
  HttpResponse,
  HttpClient,
} from 'data/protocols/http/HttpClient';

import axios, { AxiosResponse } from 'axios';

interface AuthParams {
  token: string;
  tenant?: string;
}
export class AxiosProvider implements HttpClient {
  constructor(private auth?: AuthParams) {}

  async request(data: HttpRequest): Promise<HttpResponse> {
    let axiosResponse: AxiosResponse;

    try {
      axiosResponse = await axios.request({
        url: data.url,
        method: data.method,
        headers: this.auth
          ? {
              ...data.headers,
              Authorization: `Bearer ${this.auth.token}`,
              Tenant: this.auth.tenant,
            }
          : data.headers,
        data: data.body,
        params: data.params,
      });
    } catch (error) {
      axiosResponse = error.response;
    }
    return {
      statusCode: axiosResponse.status || 500,
      body: axiosResponse.data,
    };
  }
}
