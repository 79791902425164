import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { UnexpectedError } from 'domain/errors';
import {
  BulkActionsDealsUseCase,
  BulkActionsDealsRequest,
  BulkActionsDealsResponse,
} from 'domain/usecases/deal/bulk-actions-deals-use-case';

export class RemoteBulkActionsDeals implements BulkActionsDealsUseCase {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient,
    private readonly toastService: ToastService
  ) {}

  async execute(
    payload: BulkActionsDealsRequest
  ): Promise<BulkActionsDealsResponse> {
    const response = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: {
        ids: payload.ids,
        user_id: payload.user_id || undefined,
        change_owner: payload.changeOwner || undefined,
        funnel_id: payload.funnel_id || undefined,
        result: payload.result || undefined,
        date_sale: payload.date_sale || undefined,
        date_loss: payload.date_loss || undefined,
        loss_id: payload.loss_id || undefined,
      },
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        this.toastService.show(`Dados atualizados com sucesso!`, {
          type: 'success',
        });
        return {
          bulkActions: response.body,
        };

      default:
        this.toastService.show('Erro ao atualizar os dados!', {
          type: 'error',
        });
        throw new UnexpectedError('Erro ao atualizar os dados!');
    }
  }
}
