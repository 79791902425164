import React, { ReactElement, useContext, useState } from 'react';
import {
  Card,
  Typography,
  Spacer,
  Accordion,
  Box,
  Button,
  Icon,
} from 'mino-ui';
import * as S from './styles';
import * as T from './types';
import { formatBrazilianDate } from 'main/helpers/formatBrazilianDate';
import { SingleContactContext } from 'presentation/pages/Contact/contexts/SingleContactContext';
import { useNavigate } from 'react-router';
import { makeUpdateContactUseCase } from 'main/factories/usecases/contact/update-contact-factory';
import { IconColors } from 'presentation/pages/Config/components/customTabs/component';
import { useFunnels } from 'main/hooks/useFunnels';
import { useChannels } from 'main/hooks/useChannel';
import { useUsers } from 'main/hooks/useUsers';

interface CardProps {
  products: T.ProductsProps[];
  callback: () => void;
}
const ProductCard = (props: CardProps): ReactElement => {
  const { products, callback } = props;
  const { setAddDealDrawer, contact } = useContext(SingleContactContext);
  const navigate = useNavigate();
  const { funnels } = useFunnels();
  const { channels } = useChannels();
  const { users } = useUsers();

  const [deleteLoading, setDeleteLoading] = useState(false);

  async function detachProduct(data: string[]) {
    try {
      setDeleteLoading(true);
      await makeUpdateContactUseCase(`${contact?.id}`).execute({
        name: contact?.name,
        detach_products: data,
      });
      callback();
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteLoading(false);
    }
  }

  return (
    <S.Container>
      <Card direction="column" padding="1rem 1rem 0 1rem">
        <S.Header>
          <S.TitleWrap>
            <S.IconTittle>
              <Icon iconName="Briefcase24Regular" color={IconColors.blue} />
            </S.IconTittle>
            <Typography
              variant="body"
              color="dark"
              fontSize={14}
              lineHeight={14}
              weight="bold"
              noWrap
            >
              Negócios vinculados
            </Typography>
          </S.TitleWrap>
        </S.Header>
        <S.Body>
          {products.length === 0 && (
            <Box flexDirection="column" gap={16}>
              <S.EmptyState>
                <Icon iconName="Info20Regular" />
                <Typography
                  variant="body"
                  noWrap={true}
                  fontSize={12}
                  color="gray"
                >
                  Este Contato não possui nenhum Negócio vinculado. Clique no
                  botão abaixo para vincular um agora.
                </Typography>
              </S.EmptyState>
              <Spacer />
            </Box>
          )}
          <S.ListWrap>
            <Accordion
              border={true}
              hasDivider={true}
              type="single"
              defaultValue={products.length > 0 ? products[0].id : ''}
              items={products.map((product) => ({
                value: product.id,
                trigger: (
                  <S.Trigger>
                    <Typography
                      lineHeight={12}
                      variant="body"
                      color="primary"
                      fontSize={12}
                      weight="bold"
                      noWrap
                    >
                      {product.name}
                    </Typography>
                    <button onClick={() => navigate(`/deals/${product.id}`)}>
                      <Icon iconName="Open16Regular" color={IconColors.blue} />
                    </button>
                  </S.Trigger>
                ),
                content: (
                  <S.ItensWrapper>
                    {product?.value ? (
                      <S.Item>
                        <Icon
                          iconName="ShoppingBag20Regular"
                          color={IconColors.dark}
                        />
                        <Typography
                          variant="body"
                          color="gray"
                          fontSize={12}
                          weight="regular"
                        >
                          <span>Valor: </span>R$ {product.value}
                        </Typography>
                      </S.Item>
                    ) : null}

                    {product?.created_at && (
                      <S.Item>
                        <Icon
                          iconName="CalendarLtr20Regular"
                          color={IconColors.dark}
                        />
                        <Typography
                          variant="body"
                          color="gray"
                          fontSize={12}
                          weight="regular"
                        >
                          <span>Criado em: </span>
                          {formatBrazilianDate({
                            date: new Date(product.created_at),
                          })}
                        </Typography>
                      </S.Item>
                    )}

                    {product?.funnel_id && (
                      <S.Item>
                        <Icon
                          iconName="ColumnTriple20Regular"
                          color={IconColors.dark}
                        />
                        <Typography
                          variant="body"
                          color="gray"
                          fontSize={12}
                          weight="regular"
                        >
                          <span>Funil: </span>
                          {
                            funnels.find((f) => f.id === product?.funnel_id)
                              ?.name
                          }
                        </Typography>
                      </S.Item>
                    )}

                    {product?.user_id && (
                      <S.Item>
                        <Icon
                          iconName="Person20Regular"
                          color={IconColors.dark}
                        />
                        <Typography
                          variant="body"
                          color="gray"
                          fontSize={12}
                          weight="regular"
                        >
                          <span>Proprietário: </span>
                          {users.find((f) => f.id === product.user_id)?.name}
                        </Typography>
                      </S.Item>
                    )}

                    {product?.channel_id && (
                      <S.Item>
                        <Icon
                          iconName="Channel20Regular"
                          color={IconColors.dark}
                        />
                        <Typography
                          variant="body"
                          color="gray"
                          fontSize={12}
                          weight="regular"
                        >
                          <span>Canal: </span>
                          {
                            channels.find((f) => f.id === product.channel_id)
                              ?.name
                          }
                        </Typography>
                      </S.Item>
                    )}

                    {product?.payment && (
                      <S.Item>
                        <Icon
                          iconName="Payment20Regular"
                          color={IconColors.dark}
                        />
                        <Typography
                          variant="body"
                          color="gray"
                          fontSize={12}
                          weight="regular"
                        >
                          <span>Pagamento: </span>
                          {product.payment === 'recurrent'
                            ? 'Recorrente'
                            : 'Unico'}
                        </Typography>
                      </S.Item>
                    )}
                  </S.ItensWrapper>
                ),
              }))}
            />
          </S.ListWrap>
          <S.ButtonWrap>
            <Button
              variant="ghost"
              text="Novo negócio"
              icon="AddCircle20Regular"
              iconColor={IconColors.blue}
              onClick={() => setAddDealDrawer(true)}
            />
          </S.ButtonWrap>
        </S.Body>
      </Card>
    </S.Container>
  );
};

export default ProductCard;
