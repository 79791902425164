import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ICustomField } from 'domain/entity/ICustomField';
import { UnexpectedError } from 'domain/errors';
import {
  CreateCustomField,
  CreateCustomFieldPayload,
} from 'domain/usecases/custom_field/create-custom-field';

export class RemoteCreateCustomFields implements CreateCustomField {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}

  async execute(payload: CreateCustomFieldPayload): Promise<ICustomField> {
    const response = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: {
        ...payload,
        parentType: payload.parent_type,
      },
    });

    if (response.statusCode === HttpStatusCode.ok) {
      return response.body;
    }

    throw new UnexpectedError('Erro ao criar campos personalizados');
  }
}
