import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import {
  CreateSaleUseCase,
  CreateSaleRequest,
  CreateSaleResponse,
} from 'domain/usecases/sales/create-sale-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteCreateSale implements CreateSaleUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(params: CreateSaleRequest): Promise<CreateSaleResponse> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      method: 'post',
      url: this.url,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Tenant: tenant,
      },
      body: {
        deal_id: params.deal_id,
        product_id: params.product_id,
        quantity: params.quantity,
        discount: params.discount,
        total: params.total,
      },
    });

    const sale = response.body;

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return {
          id: sale.id,
          discount: sale.discount,
          deal_id: sale.deal_id,
          quantity: sale.quantity,
          product_id: sale.product_id,
          total: sale.total,
        };

      case HttpStatusCode.unauthorized:
        this.toastService.show('Não autorizado', {
          type: 'error',
        });
        throw new NotAuthorizedError('Unauthorized');
      default:
        this.toastService.show('Erro no servidor', {
          type: 'error',
        });
        throw new UnexpectedError('Unexpected error');
    }
  }
}
