import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import {
  GetReportUsersDealsUseCase,
  UsersDealsRequestDTO,
  UsersDealsResponseDTO,
} from 'domain/usecases/deal/report-users-deals.use-case';

export class RemoteGetUsersDealsReport implements GetReportUsersDealsUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string
  ) {}

  async execute(
    params: UsersDealsRequestDTO
  ): Promise<UsersDealsResponseDTO[]> {
    const response = await this.httpClient.request({
      method: 'get',
      url: this.url,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params,
    });

    const data = response.body;

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return data
          .sort((a, b) => b.dealsWon - a.dealsWon)
          .map((report) => ({
            name: report.name,
            avatar: report.avatar,
            dealsWon: report.deals_won,
          }));

      case HttpStatusCode.unauthorized:
        throw new NotAuthorizedError('Unauthorized');
      default:
        throw new UnexpectedError('Unexpected error');
    }
  }
}
