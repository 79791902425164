import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { DealResponseDTO } from 'domain/dtos/deal/deal-response-dto';
import { IDeal } from 'domain/entity/IDeal';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import {
  DealRequestPayload,
  ListDealsUseCase,
} from 'domain/usecases/deal/list-deals-use-case';

export class RemoteListDeals implements ListDealsUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(params: DealRequestPayload): Promise<DealResponseDTO> {
    const response = await this.httpClient.request({
      method: 'get',
      url: this.url,
      params: {
        ...params,
        name: params.search,
        created_from: params.date_from,
        created_to: params.date_to,
        users_ids: params.users_ids?.join(',') || undefined,
        teams: params.teams?.join(',') || undefined,
        contacts: params.contacts?.join(',') || undefined,
        companies: params.companies?.join(',') || undefined,
        products: params.products?.join(',') || undefined,
        custom_fields: Object.entries(params)
          .filter(([key]) => key.startsWith('cf_'))
          .reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key.replace('cf_', '')]: value,
            }),
            {}
          ),
      },
    });

    const { data } = response.body;

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return {
          total: response.body.total,
          perPage: response.body.perPage,
          lastPage: response.body.lastPage,
          page: response.body.page,
          totalValue: response.body?.sum_values?.Value ?? 0,
          data: data.map((deal: IDeal) => ({
            ...(deal as IDeal),
          })),
        };

      case HttpStatusCode.unauthorized:
        this.toastService.show('Unauthorized', {
          type: 'error',
        });
        throw new NotAuthorizedError('Unauthorized');
      default:
        this.toastService.show('Unexpected error', {
          type: 'error',
        });
        throw new UnexpectedError('Unexpected error');
    }
  }
}
