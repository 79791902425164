import React, { ReactElement, useState } from 'react';
import { Box, Button, Input, Table, Typography, Dialog, Select } from 'mino-ui';
import * as S from './styles';
import AddIcon from './icons/add.svg';
import { ManageDrawer } from './ManageDrawer';
import { pressEscKey } from 'main/helpers/scape-key-esc';
import { RequestPayloadTags } from 'domain/usecases/tag/save-tag-use-case';
import { useTags } from 'main/hooks/useTags';
import { useForm } from 'react-hook-form';
import { ReactSVG } from 'react-svg';
import Tag20Filled from './icons/Tag20Filled.svg';

const TagsConfig = (): ReactElement => {
  const [manageOpen, setManageOpen] = useState(false);
  const [updatePayload, setUpdatePayload] = useState<RequestPayloadTags>();
  const [createLoading, setCreateLoading] = useState(false);

  const { tags, addTag, deleteTag } = useTags();

  function handleSelectChange(value: string): void {
    setValue('color', value);
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<RequestPayloadTags>({
    defaultValues: { name: '', color: 'blue' },
  });

  async function saveNewTag(data: RequestPayloadTags) {
    try {
      setCreateLoading(true);
      await addTag(data);

      setValue('name', '');
      setValue('color', '');
    } catch (error) {
      console.log(error);
    } finally {
      setCreateLoading(false);
    }
  }

  return (
    <Box flexDirection="column" gap={16}>
      <Box gap={16}>
        <form
          onSubmit={handleSubmit(saveNewTag)}
          style={{ display: 'flex', gap: 8 }}
        >
          <Input
            full
            autoComplete="off"
            register={register}
            errors={errors}
            validations={{
              required: true,
            }}
            placeholder="Ex: Cliente"
            name="name"
          />
          <Select
            fullWidth
            onChange={(v) => v && handleSelectChange(v.value)}
            defaultValue={'blue'}
            options={[
              {
                value: 'blue',
                label: 'Azul',
              },
              {
                value: 'red',
                label: 'Vermelho',
              },
              {
                value: 'green',
                label: 'Verde',
              },
              {
                value: 'yellow',
                label: 'Amarelo',
              },
              {
                value: 'violet',
                label: 'Roxo',
              },
              {
                value: 'orange',
                label: 'Laranja',
              },
              {
                value: 'teal',
                label: 'Azul claro',
              },
              {
                value: 'gray',
                label: 'Cinza',
              },
            ]}
            placeholder="Selecione a cor"
          />

          <Button
            disabled={createLoading}
            icon={AddIcon}
            text="Adicionar"
            type="submit"
          />
        </form>
      </Box>

      <Table
        hasOutline
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            width: 70,
          },
          {
            field: 'color',
            headerName: 'Cor da Tag',
            hiddenOnMobile: true,
            align: 'center',
            width: 30,
          },
          {
            field: 'actions',
            headerName: '',
          },
        ]}
        rows={tags.map((c) => ({
          id: c.id,
          name: c.name,
          color: (
            <S.radioWrap color={c.color}>
              <ReactSVG src={Tag20Filled} />
            </S.radioWrap>
          ),
          actions: (
            <Box gap={8}>
              <Button
                size="small"
                onClick={() => {
                  setUpdatePayload(c);
                  setManageOpen(true);
                }}
                variant="ghost"
                text="Editar"
              />

              <Dialog
                modal
                title="Atenção!"
                trigger={
                  <Button text="Excluir" size="small" variant="secondary" />
                }
                content={
                  <Box flexDirection="column" gap={32}>
                    <Typography noWrap variant="body">
                      Tem certeza que deseja excluir essa Tag?
                    </Typography>

                    <Box gap={8}>
                      <Button
                        text="Excluir"
                        variant="primary"
                        onClick={() => deleteTag(c.id)}
                      />
                      <Button
                        onClick={() => pressEscKey()}
                        variant="outline"
                        text="Cancelar"
                      />
                    </Box>
                  </Box>
                }
              />
            </Box>
          ),
        }))}
      />

      <ManageDrawer
        updatePayload={updatePayload}
        isOpen={manageOpen}
        setIsOpen={setManageOpen}
        callback={() => {
          setUpdatePayload(undefined);
        }}
      />
    </Box>
  );
};

export default TagsConfig;
