import React, { ReactElement, useContext } from 'react';
import { Collapse, Drawer, Input, Select, Spacer } from 'mino-ui';
import { useForm } from 'react-hook-form';
import ContactListContext from './context/ContactListContext';
import { RequestParamsListContacts } from 'domain/usecases/contact/list-contact-use-case';
import * as S from './styles';
import { useChannels } from 'main/hooks/useChannel';
import FilterIcon from './icons/filter.svg';
import { useCompanies } from 'main/hooks/useCompanies';
import { BrazilianStates } from 'config/consts/brazilian-states';
import { useCustomFields } from 'main/hooks/useCustomFields';

const DrawerFilterContact = (): ReactElement => {
  const { filterOpen, setFilterOpen, filter, setFilter, initialFilter } =
    useContext(ContactListContext);

  const { channels } = useChannels();
  const { companies } = useCompanies();

  function handleFilter(data: RequestParamsListContacts): void {
    setFilter({ ...data });
    setFilterOpen(false);
  }

  function resetFilter() {
    reset();
    setFilter(initialFilter);
    setFilterOpen(false);
  }

  const { register, handleSubmit, reset, control } =
    useForm<RequestParamsListContacts>({
      defaultValues: {
        search: '',
        channel_id: undefined,
        company_id: undefined,
        state: undefined,
        context: 'self',
      },
    });

  const { custom_fields } = useCustomFields('contact');

  return (
    <Drawer
      width={300}
      isOpen={filterOpen}
      onClose={() => setFilterOpen(false)}
      title="Filtrar Contatos"
      icon={FilterIcon}
      submitText="Filtrar"
      onSubmit={handleSubmit(handleFilter)}
      secondarySubmitText="Limpar"
      secondarySubmitAction={() => {
        resetFilter();
      }}
    >
      <form onSubmit={handleSubmit(handleFilter)}>
        <S.DrawerForm>
          <Collapse
            defaultOpen={true}
            hasArrow
            trigger={
              <S.WrapTrigger>
                <S.TriggerText>Informações pessoais</S.TriggerText>
              </S.WrapTrigger>
            }
            content={
              <>
                <Input
                  autoFocus
                  register={register}
                  label="Nome"
                  name="search"
                  placeholder="Digite o nome do contato"
                />
                <Input
                  register={register}
                  label="Email"
                  name="email"
                  placeholder="Digite o email do contato"
                />
                <Input
                  register={register}
                  label="CPF"
                  name="cpf"
                  placeholder="Digite o CPF do contato"
                />
              </>
            }
          />

          <Collapse
            hasArrow
            trigger={
              <S.WrapTrigger>
                <S.TriggerText>Proprietário</S.TriggerText>
              </S.WrapTrigger>
            }
            content={
              <Select
                fullWidth
                label="Prorpietário"
                defaultValue={filter.context}
                control={control}
                name="context"
                options={[
                  {
                    value: 'self',
                    label: 'Meus',
                  },
                  {
                    value: 'all',
                    label: 'Todos',
                  },
                ]}
              />
            }
          />

          <Collapse
            hasArrow
            trigger={
              <S.WrapTrigger>
                <S.TriggerText>Origem</S.TriggerText>
              </S.WrapTrigger>
            }
            content={
              <Select
                fullWidth
                name="channel_id"
                control={control}
                placeholder="Selecione a origem"
                label="Origem"
                options={[
                  {
                    label: 'Nenhum',
                    value: '',
                  },
                  ...channels.map((c) => ({
                    label: c.name,
                    value: c.id,
                  })),
                ]}
              />
            }
          />
          <Collapse
            hasArrow
            trigger={
              <S.WrapTrigger>
                <S.TriggerText>Empresa</S.TriggerText>
              </S.WrapTrigger>
            }
            content={
              <Select
                fullWidth
                name="company_id"
                control={control}
                placeholder="Selecione a empresa"
                label="Empresa"
                options={[
                  {
                    label: 'Nenhuma',
                    value: '',
                  },
                  ...companies.map((c) => ({
                    label: c.name,
                    value: c.id,
                  })),
                ]}
              />
            }
          />
          <Collapse
            hasArrow
            trigger={
              <S.WrapTrigger>
                <S.TriggerText>Endereço</S.TriggerText>
              </S.WrapTrigger>
            }
            content={
              <>
                <Select
                  fullWidth
                  name="state"
                  control={control}
                  placeholder="Selecione o estado"
                  label="Estado"
                  options={BrazilianStates.map((es) => ({
                    label: es.nome,
                    value: es.sigla,
                  }))}
                />
                <Input
                  register={register}
                  label="Cidade"
                  name="city"
                  placeholder="Digite a cidade do contato"
                />
                <Input
                  register={register}
                  label="CEP"
                  name="zip"
                  placeholder="Digite o CEP do contato"
                />
              </>
            }
          />

          {custom_fields?.map((field) => (
            <Collapse
              key={field.id}
              hasArrow
              trigger={
                <S.WrapTrigger>
                  <S.TriggerText>{field.name}</S.TriggerText>
                </S.WrapTrigger>
              }
              content={
                <>
                  <Input
                    label={field.name}
                    full
                    register={register}
                    name={`cf_${field.id}`}
                    placeholder={`${field.name}`}
                  />
                </>
              }
            />
          ))}

          <Spacer height={16} />
        </S.DrawerForm>
        <input type="submit" hidden />
      </form>
    </Drawer>
  );
};

export default DrawerFilterContact;
