import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { LoginResponseDTO } from 'domain/dtos/login-response-dto';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import { LoginUseCase } from 'domain/usecases/session/login-use-case';

export class RemoteLoginUseCase implements LoginUseCase {
  constructor(
    private readonly httpClient: HttpClient<LoginResponseDTO>,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(email: string, password: string): Promise<LoginResponseDTO> {
    const response = await this.httpClient.request({
      url: this.url,
      method: 'post',
      params: {
        Email: email,
        Password: password,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = response.body || {
      token: '',
    };

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return data;

      case HttpStatusCode.unauthorized:
        this.toastService.show('Usuário ou senha inválidos', {
          type: 'error',
        });
        throw new NotAuthorizedError('Unauthorized');

      default:
        this.toastService.show('Erro inesperado', {
          type: 'error',
        });
        throw new UnexpectedError('Unexpected Error');
    }
  }
}
