import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { TagResponse } from 'domain/usecases/tag/list-tags-use-case';
import { makeListTagUseCase } from 'main/factories/usecases/tags/list-tags-factory';
import { makeDeleteTagUseCase } from 'main/factories/usecases/tags/delete-tag-factory';
import { makeSaveTagUseCase } from 'main/factories/usecases/tags/save-tags-factory';

type UseTagsType = {
  tags: TagResponse[];
  isLoading: boolean;
  isError: boolean;
  addTag: (tag: { name: string; color: string }) => Promise<void>;
  updateTag: (tag: TagResponse) => Promise<void>;
  deleteTag: (tagId: string) => Promise<void>;
};

export const useTags = (): UseTagsType => {
  const queryClient = useQueryClient();

  const {
    data: tags = [],
    isLoading,
    isError,
  } = useQuery<TagResponse[]>({
    queryKey: ['tags'],
    queryFn: () => makeListTagUseCase().execute(),
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const addTagMutation = useMutation({
    mutationFn: (newTag: { name: string; color: string }) =>
      makeSaveTagUseCase().execute(newTag, 'create'),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tags'] });
    },
  });

  const updateTagMutation = useMutation({
    mutationFn: (updatedTag: TagResponse) =>
      makeSaveTagUseCase(updatedTag.id).execute(updatedTag, 'update'),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tags'] });
    },
  });

  const deleteTagMutation = useMutation({
    mutationFn: (tagId: string) => makeDeleteTagUseCase(tagId).execute(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tags'] });
    },
  });

  const addTag = async (tag: {
    name: string;
    color: string;
  }): Promise<void> => {
    await addTagMutation.mutateAsync(tag);
  };

  const updateTag = async (tag: TagResponse): Promise<void> => {
    await updateTagMutation.mutateAsync(tag);
  };

  const deleteTag = async (tagId: string): Promise<void> => {
    await deleteTagMutation.mutateAsync(tagId);
  };

  return { tags, isLoading, isError, addTag, updateTag, deleteTag };
};
