import { RemoteDeleteDeal } from 'data/usecases/deal/remote-delete-deals';
import { DeleteDealUseCase } from 'domain/usecases/deal/delete-deal-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeDeleteDealUseCase = (dealId: string): DeleteDealUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteDeleteDeal(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/deals/${encodeURI(dealId)}`,
    toastify
  );
};
