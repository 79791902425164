import { HttpClient } from 'data/protocols/http/HttpClient';
import { IRole } from 'domain/entity/IRole';
import { ListRolesUseCase } from 'domain/usecases/role/list-roles-use-case';

export class RemoteListRolesUseCase implements ListRolesUseCase {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}

  async execute(): Promise<IRole[]> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'get',
    });

    if (httpResponse.statusCode !== 200) {
      throw new Error('Unexpected error');
    }

    return httpResponse.body;
  }
}
