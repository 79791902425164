import React, { ReactElement, useState, useEffect, useContext } from 'react';
import { Drawer, Input, Select } from 'mino-ui';
import * as S from './styles';
import { IContact } from 'domain/entity/IContact';
import { ICompany } from 'domain/entity/ICompany';
import { UpdateContactRequest } from 'domain/usecases/contact/update-contact-use-case';
import { makeUpdateContactUseCase } from 'main/factories/usecases/contact/update-contact-factory';
import { useForm } from 'react-hook-form';
import Add from '../icons/add-user.svg';
import { useCompanies } from 'main/hooks/useCompanies';
import { useDebouncedEffect } from 'presentation/hooks/useDebouncedEffect';
import { useChannels } from 'main/hooks/useChannel';
import { useUsers } from 'main/hooks/useUsers';
import { BrazilianStates } from 'config/consts/brazilian-states';
import { SingleContactContext } from '../../contexts/SingleContactContext';

interface EditDrawerProps {
  isDrawerOpen: boolean;
  setDrawerOpen: (isOpen: boolean) => void;
  contact: IContact;
  callback: () => void;
  company?: ICompany;
}

const EditContactDrawer = (props: EditDrawerProps): ReactElement => {
  const { isDrawerOpen, setDrawerOpen, callback, contact } = props;
  const { all_custom_fields } = useContext(SingleContactContext);

  const [companySearch, setCompanySearch] = useState('');
  const [load, setLoad] = useState(false);

  const { fetchCompanies, companies } = useCompanies();
  const { channels } = useChannels();

  const [userSearch, setUserSearch] = useState('');
  const { users, fetchUsers } = useUsers();

  async function updateContact(data: UpdateContactRequest) {
    try {
      setLoad(true);

      const custom_fields: UpdateContactRequest['custom_fields'] = {};

      for (const field of all_custom_fields) {
        custom_fields['cf_' + field.id] = data[`cf_${field.id}`] || '';
      }

      await makeUpdateContactUseCase(`${contact.id}`).execute({
        ...data,
        custom_fields,
      });
      reset();
      callback();
      setDrawerOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoad(false);
    }
  }

  useDebouncedEffect(
    () => {
      if (companySearch.length > 0) {
        fetchCompanies(companySearch);
      }

      if (userSearch.length > 0) {
        fetchUsers(userSearch);
      }
    },
    [companySearch, userSearch],
    300
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
  } = useForm<UpdateContactRequest>();

  useEffect(() => {
    if (contact) {
      setValue('name', contact.name);
      setValue('email', contact.email);
      setValue('phone', contact.phone);
      setValue('description', contact.description);
      setValue('company_id', contact?.company_id);
      setValue('channel_id', contact?.channel_id);
      setValue('user_id', contact?.user?.id);
      setValue('address', contact?.address);
      setValue('city', contact?.city);
      setValue('state', contact?.state);
      setValue('zip', contact?.zip);
      setValue('cpf', contact?.cpf);

      if (contact.custom_fields?.length) {
        contact.custom_fields.forEach((field) => {
          setValue(`cf_${field.custom_field_id}` as any, field.value);
        });
      }
    }
  }, [contact, setValue]);

  return (
    <Drawer
      title="Editar Contato"
      isOpen={isDrawerOpen}
      onClose={() => setDrawerOpen(false)}
      width={300}
      icon={Add}
      onSubmit={handleSubmit(updateContact)}
      submitText={load ? 'Salvando...' : 'Salvar'}
      secondarySubmitAction={() => setDrawerOpen(false)}
      secondarySubmitText="Cancelar"
    >
      <form onSubmit={handleSubmit(updateContact)}>
        <S.EditDrawerForm>
          <Input
            autoFocus
            label="Nome"
            placeholder="Nome do contato"
            type="text"
            name="name"
            register={register}
            errors={errors}
            validations={{
              required: true,
            }}
          />
          <Input
            label="Email"
            placeholder="E-mail do contato"
            type="text"
            required
            name="email"
            register={register}
            errors={errors}
            validations={{
              pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
            }}
          />
          <Input
            label="Telefone"
            placeholder="Telefone do contato"
            type="text"
            name="phone"
            register={register}
            errors={errors}
          />
          <Select
            fullWidth
            name="company_id"
            onInputChange={(v) => setCompanySearch(v)}
            control={control}
            placeholder="Selecione a empresa"
            label="Empresa"
            options={companies.map((c) => ({
              label: c.name,
              value: c.id,
            }))}
          />
          <Select
            fullWidth
            name="channel_id"
            onInputChange={(v) => setCompanySearch(v)}
            control={control}
            placeholder="Selecione a origem"
            label="Origem"
            options={channels.map((c) => ({
              label: c.name,
              value: c.id,
            }))}
          />
          <Input
            label="CPF"
            type="text"
            name="cpf"
            placeholder="CPF do contato"
            register={register}
          />
          <Select
            fullWidth
            label="Estado"
            name="state"
            onInputChange={(v) => setCompanySearch(v)}
            control={control}
            placeholder="Selecione o estado"
            options={BrazilianStates.map((es) => ({
              label: es.nome,
              value: es.sigla,
            }))}
          />
          <Input
            label="Cidade"
            placeholder="Cidade do contato"
            type="text"
            name="city"
            register={register}
          />
          <Input
            label="Endereço"
            placeholder="Endereço do contato"
            type="text"
            name="address"
            register={register}
            errors={errors}
          />
          <Input
            label="CEP"
            placeholder="CEP do contato"
            type="text"
            name="zip"
            register={register}
          />

          <Input
            label="Observações"
            name="description"
            placeholder="Observações"
            type="text"
            register={register}
            errors={errors}
          />

          <Select
            fullWidth
            name="user_id"
            onInputChange={(v) => setUserSearch(v)}
            control={control}
            placeholder="Selecione o dono"
            label="Dono"
            options={users.map((u) => ({
              label: u.name,
              value: u.id,
            }))}
          />

          {all_custom_fields?.map((field) => (
            <Input
              key={field.id}
              full
              label={field.name}
              placeholder={field.name}
              type="text"
              name={`cf_${field.id}`}
              register={register}
            />
          ))}

          <input type="submit" hidden />
        </S.EditDrawerForm>
      </form>
    </Drawer>
  );
};
export default EditContactDrawer;
