import React, { ReactElement, useContext, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  Box,
  Card,
  Avatar,
  Typography,
  RichText,
} from 'mino-ui';
import { CallLink, WrapButtons, WrapContent } from '../styles';
import CallPrimary from '../icons/callPrimary.svg';
import CallSecondary from '../icons/callSecondary.svg';
import Clock from '../icons/clock.svg';
import TypeCall from '../icons/typeCallButton.svg';
import { ActivityContext } from 'presentation/pages/Activity/context/ActivityContext';
import { useForm } from 'react-hook-form';
import { isMobile } from 'react-device-detect';
import { UploadFile } from './UploadFile';
import { ActivityStatus, ActivityType } from 'domain/entity/IActivity';

type ActivityPayload = {
  title: string;
  description: string;
  start: Date;
  end: Date;
  status: ActivityStatus;
  type: ActivityType;
};

const CallTab = (): ReactElement => {
  const [openCall, setOpenCall] = useState(false);

  const [descriptionEditor, setDescriptionEditor] = useState(
    'Registro de ligação'
  );

  const {
    load,
    handleSubmit,
    setOpenSchedule,
    setScheduleActivityType,
    phoneNumbers,
    setFiles,
    files,
  } = useContext(ActivityContext);

  const hasContacts = phoneNumbers && phoneNumbers?.length > 0;

  async function submitActivity(data: ActivityPayload) {
    try {
      await handleSubmit({ ...data, description: descriptionEditor });

      setDescriptionEditor('');
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    setScheduleActivityType(ActivityType.CALL);
  }, [setScheduleActivityType]);

  const { handleSubmit: handleFormSubmit } = useForm<ActivityPayload>({
    defaultValues: {
      title: 'Ligação',
      type: ActivityType.CALL,
      description: descriptionEditor,
      start: new Date(),
      end: new Date(),
      status: ActivityStatus.FINISHED,
    },
  });

  function handleNativeCall(number: string) {
    window.open(
      `tel:${number.replace(/\D/g, '')}`,
      '_blank',
      'location=yes,height=570,width=520,scrollbars=yes,status=yes'
    );
  }

  return (
    <WrapContent>
      <form onSubmit={handleFormSubmit(submitActivity)}>
        <RichText
          value={descriptionEditor}
          onChange={(e) => setDescriptionEditor(e)}
        />

        <UploadFile files={files} setFiles={setFiles} />

        <WrapButtons>
          {hasContacts ? (
            <Button
              size="medium"
              text="Ligar"
              icon={TypeCall}
              variant="primary"
              type="button"
              onClick={() => setOpenCall(true)}
              noWrap
            />
          ) : null}

          <Button
            size="medium"
            text="Registrar ligação"
            icon={hasContacts ? CallSecondary : CallPrimary}
            variant={hasContacts ? 'ghostSecondary' : 'primary'}
            loading={load}
            hiddenTextOnMobile
            type="submit"
            noWrap
          />

          <Button
            type="button"
            size="medium"
            text="Agendar ligação"
            hiddenTextOnMobile
            icon={Clock}
            variant="ghostSecondary"
            onClick={() => setOpenSchedule(true)}
            noWrap
          />
        </WrapButtons>
      </form>

      {phoneNumbers && phoneNumbers?.length > 0 ? (
        <Dialog
          open={openCall}
          modal
          onOpenChange={(v) => setOpenCall(v)}
          title="Para qual número deseja ligar?"
          content={
            <Card padding="0" gap={16} direction="column">
              <Typography variant="body" color="dark" noWrap>
                Verifique se o número selecionado está corretamente cadastrado.
              </Typography>

              {phoneNumbers.map((phone, index) => (
                <CallLink
                  key={index}
                  onClick={() => {
                    handleNativeCall(phone.phone);
                    setOpenCall(false);
                  }}
                >
                  <Box gap={8} alignItems="center" style={{ maxWidth: '50%' }}>
                    <Avatar name={phone.name} />
                    <Typography
                      ellipsis={!isMobile}
                      variant="body"
                      color="primary"
                    >
                      {phone.name}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="body" color="primary">
                      {phone.phone}
                    </Typography>
                  </Box>
                </CallLink>
              ))}
            </Card>
          }
        />
      ) : null}
    </WrapContent>
  );
};
export default CallTab;
