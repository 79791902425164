import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SessionContext } from 'presentation/layout/contexts/SessionContext';
import { IntegrationCard } from '../components/IntegrationCard';
import Zenvia from '../icons/zenvia.svg';
import Zapier from '../icons/zapier.svg';
import Meta from '../icons/meta.svg';
import Rd from '../icons/RdStationLogo.svg';
import Copy from '../icons/copy.svg';
import * as S from './styles';
import { SingleIntegrationPage } from '../single/component';
import { IntegrationCardProps } from '../components/types';
import { IntegrateZenvia } from '../components/integrate/zenvia/IntegrateZenvia';
import { IntegrateRD } from '../components/integrate/rd/integrateRD';
import { Box, Card, Input, Typography } from 'mino-ui';
import { ReactSVG } from 'react-svg';
import { makeToastify } from 'main/factories/notification';
import IntegrateFacebook from '../components/integrate/facebook/IntegrateFacebook';

export const Integrations = (): ReactElement => {
  const { session } = useContext(SessionContext);
  const [pageType, setPageType] = useState<'single' | 'list'>('list');

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const app = urlParams.get('app')?.toString();

  const apps = useMemo(
    () => [
      {
        id: '10',
        logo: Zenvia,
        active: session?.integrations?.zenvia.active,
        name: 'Zenvia Total Voice',
        description: 'Receba e realize ligações com a sua conta Zenvia.',
        content: <IntegrateZenvia />,
        documentation:
          '* É necessário criar uma conta no Zenvia Total Voice para ter acesso ao Token fornecido. <br/> <br/> * Para criar uma conta acesse: <a target="_blank" href="https://voice-app.zenvia.com/painel/login.php"> https://voice-app.zenvia.com/painel/login.php </a> <br/> <br/> * Após criar uma conta, lembre-se de adicionar os Ramais nos usuários do CRM.',
      },
      {
        id: '20',
        logo: Rd,
        active: session?.integrations?.rd.access_token,
        name: 'Rd Station',
        description: 'Receba e rotacione Leads para seus vendedores.',
        content: <IntegrateRD />,
        documentation: `* É necessário criar uma conta no RD Station Marketing.`,
      },
      {
        id: '30',
        logo: Zapier,
        name: 'Zapier',
        description: 'Integre o Taskflow CRM com mais de 2.000 aplicativos.',
        documentation: `* É necessário criar uma conta no Zapier.`,
        content: (
          <zapier-full-experience
            client-id="Xm336RhA3lQYLnC4IE8Gjew1zjAuWO1YZ1MJUoxd"
            theme="light"
            app-search-bar-display="show"
          />
        ),
      },
      {
        id: '40',
        logo: Meta,
        name: 'Facebook Ads',
        description: 'Integre o Taskflow CRM com o Facebook Ads.',
        documentation: `* É necessário criar uma conta no Facebook Ads manager.`,
        content: <IntegrateFacebook />,
      },
    ],
    [session]
  );

  const [appSelected, setAppSelected] = useState<IntegrationCardProps>();

  function CopyCode() {
    const toastfy = makeToastify();

    if (session.tenant.api_token) {
      navigator.clipboard.writeText(session.tenant.api_token);
    }

    return toastfy.show('Copiado para a área de transferência', {
      type: 'success',
    });
  }

  useEffect(() => {
    const defaultApp = apps.find((i) => i.id == app);

    if (defaultApp) {
      setAppSelected(defaultApp);
      setPageType('single');
    }
  }, [app, apps]);

  return (
    <>
      {pageType === 'list' ? (
        <>
          <Card padding="0 8px">
            <Box flexDirection="column" gap={16}>
              <Typography variant="body" weight="bold">
                API KEY - Código de Integração do Taskflow CRM
              </Typography>

              <Typography variant="body" fontSize={12} noWrap>
                Utilize o código abaixo para fazer integrações com o Taskflow
                CRM. Basta clicar no botão Copiar.
              </Typography>
              <S.InputWrap>
                <Input
                  name="code"
                  full
                  placeholder={session.tenant.api_token}
                  disabled
                  value={session.tenant.api_token}
                />
                <S.IconWrap onClick={CopyCode}>
                  <ReactSVG src={Copy} />
                </S.IconWrap>
              </S.InputWrap>
            </Box>
          </Card>
          <S.listContainer>
            {apps.map((app) => (
              <IntegrationCard
                key={app.id}
                content={app.content}
                id={app.id}
                logo={app.logo}
                active={app.active}
                name={app.name}
                description={app.description}
                documentation={app.documentation}
                action={() => {
                  setAppSelected(app);
                  setPageType('single');
                }}
              />
            ))}
          </S.listContainer>
        </>
      ) : (
        <>
          {appSelected && (
            <SingleIntegrationPage
              id={appSelected.id}
              content={appSelected.content}
              description={appSelected?.description}
              documentation={appSelected?.documentation}
              logo={appSelected?.logo}
              name={appSelected?.name}
              callback={() => {
                setPageType('list');
                setAppSelected(undefined);
              }}
            />
          )}
        </>
      )}
    </>
  );
};
