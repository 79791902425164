import React, { createRef, ReactElement, useEffect, useState } from 'react';
import { Box, Input, Label, Dialog, Checkbox, Divider } from 'mino-ui';
import { SaveGroupRequestPayload } from 'domain/usecases/group[deprecated]/save-group-use-case';
import {
  global_permission_modes,
  global_permissions,
  translatePermission,
} from 'presentation/hooks/usePermissions';
import { SaveRoleRequest } from 'domain/usecases/role/save-role-use-case';
import { SaveRolesUseCaseFactory } from 'main/factories/usecases/roles/save-roles-use-case-factory';

export type UpdateGroupPayload = SaveGroupRequestPayload & {
  contactRoleId: string;
  productRoleId: string;
  configRoleId: string;
};

interface ManageDrawerProps {
  callback: () => void;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  updatePayload?: SaveRoleRequest;
}

export const ManageDrawer = (props: ManageDrawerProps): ReactElement => {
  const { isOpen, setIsOpen, callback, updatePayload } = props;

  const [loading, setLoading] = useState(false);

  const [roleName, setRoleName] = useState(updatePayload?.name || '');
  const [rolePermissions, setRolePermissions] = useState(
    updatePayload?.permissions || []
  );

  async function handleSaveRole() {
    try {
      setLoading(true);
      await SaveRolesUseCaseFactory(updatePayload?.id).execute({
        name: roleName,
        permissions: rolePermissions,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      callback();
    }
  }

  useEffect(() => {
    if (updatePayload) {
      setRoleName(updatePayload.name);
      setRolePermissions(updatePayload.permissions);
    }
  }, [updatePayload]);

  return (
    <Dialog
      open={isOpen}
      maxWidth={800}
      onOpenChange={(s) => {
        setIsOpen(s);
        if (!s) {
          setRoleName('');
          setRolePermissions([]);
        }
      }}
      title={'Novo Grupo de Usuários'}
      submitText={'Salvar'}
      onSubmit={() => handleSaveRole()}
      loading={loading}
      content={
        <div>
          <Box gap={8} flexDirection="column">
            <Input hidden type="text" name="id" />

            <Input
              label="Nome do Grupo"
              placeholder="Ex: Colaboradores"
              name="name"
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
            />

            <Divider
              style={{
                marginTop: 16,
                marginBottom: 16,
                backgroundColor: '#e0e0e0',
              }}
            />

            <Box flexDirection="column" gap={8}>
              {global_permissions.map((permission) => (
                <Box
                  key={permission}
                  flexDirection="row"
                  justifyContent="space-between"
                  gap={8}
                  style={{ height: 48, borderBottom: '1px solid #e0e0e0' }}
                  alignItems="center"
                >
                  <strong>{translatePermission(permission)}</strong>

                  <Box gap={4}>
                    {global_permission_modes.map((mode) => {
                      return (
                        <label
                          key={mode}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 8,
                            border: '1px solid #e0e0e0',
                            padding: 8,
                            borderRadius: 4,
                            cursor: 'pointer',
                          }}
                        >
                          <div>{mode}</div>

                          <Checkbox
                            onCheckedChange={(checked) => {
                              if (checked) {
                                setRolePermissions([
                                  ...rolePermissions,
                                  `${permission}:${mode}`,
                                ]);
                              } else {
                                setRolePermissions(
                                  rolePermissions.filter(
                                    (p) => p !== `${permission}:${mode}`
                                  )
                                );
                              }
                            }}
                            name={mode}
                            checked={rolePermissions.includes(
                              `${permission}:${mode}`
                            )}
                          />
                        </label>
                      );
                    })}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </div>
      }
    />
  );
};
