import React, { ReactElement, useEffect, useState } from 'react';
import { makeUpdateDealUseCase } from 'main/factories/usecases';
import { pressEscKey } from 'main/helpers/scape-key-esc';
import {
  Box,
  Button,
  Datepicker,
  Dialog,
  Select,
  Spacer,
  Typography,
} from 'mino-ui';
import { useForm } from 'react-hook-form';
import { useLoss } from 'main/hooks/useLoss';
import { CreateActivityRequest } from 'domain/usecases/activity/create-activities-use-case';
import { makeSaveActivityUseCase } from 'main/factories/usecases/activity/save-activity-factory';
import { renderLostActivityDescription } from './actions';
import EarningsIcon from './icons/earnings.gif';
import SeedingIcon from './icons/seeding.gif';
import { ActivityStatus, ActivityType } from 'domain/entity/IActivity';
import { DealResult } from 'domain/entity/IDeal';

interface ChangeResultProps {
  type: 'sell' | 'loss' | undefined;
  open?: boolean;
  onOpenChange(val: boolean): void;
  dealId: string;
  callback?(): void;
}

const ChangeResultTemplate = (props: ChangeResultProps): ReactElement => {
  const { type, open, onOpenChange, dealId, callback } = props;

  const [loading, setLoading] = useState(false);
  const [openSell, setOpenSell] = useState(type === 'sell' && open);
  const [openLoss, setOpenLoss] = useState(type === 'loss' && open);
  const [openResetResult, setOpenResetResult] = useState(
    type === undefined && open
  );
  const { loss: lossReason } = useLoss();

  interface UpdatePayload {
    date_sale: Date;
    date_loss: Date;
    loss_id?: string;
  }

  async function handleUpdateDeal(data: UpdatePayload) {
    try {
      setLoading(true);
      await makeUpdateDealUseCase(dealId).execute({
        result: type === 'sell' ? DealResult.Won : DealResult.Lost,
        date_sale: (type === 'sell' && data.date_sale) || undefined,
        date_loss: (type === 'loss' && data.date_loss) || undefined,
        loss_reason_id: data.loss_id,
      });

      setOpenLoss(false);
      setOpenSell(false);
      onOpenChange(false);
      callback && callback();
      createActivity({
        title: type === 'sell' ? 'Venda' : 'Perda',
        type: type === 'sell' ? ActivityType.SALE : ActivityType.LOSS,
        start: (type === 'sell' ? data.date_sale : data.date_loss) || undefined,
        deal_id: dealId,
        status: ActivityStatus.FINISHED,
        description: renderLostActivityDescription({
          date: type === 'sell' ? data.date_sale : data.date_loss,
          type: type === 'sell' ? 'sell' : 'loss',
          loss: lossReason
            .filter((filteredLoss) => filteredLoss.id == data.loss_id)
            .map((f) => f.name),
        }),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function createActivity(data: CreateActivityRequest) {
    try {
      await makeSaveActivityUseCase().execute(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function resetResult() {
    try {
      setLoading(true);
      await makeUpdateDealUseCase(dealId).execute({
        result: DealResult.Open,
      });
      onOpenChange(false);
      callback && callback();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (type === 'loss') setOpenLoss(open);

    if (type === 'sell') setOpenSell(open);

    if (type === undefined) setOpenResetResult(open);
  }, [open, type]);

  const { control, handleSubmit } = useForm<UpdatePayload>();

  const { loss } = useLoss();

  return (
    <>
      <Dialog
        open={openSell}
        onSubmit={handleSubmit(handleUpdateDeal)}
        onOpenChange={(v) => {
          onOpenChange(v);
        }}
        title="Marcar venda"
        content={
          <form onSubmit={handleSubmit(handleUpdateDeal)}>
            <Box flexDirection="column" alignItems="center" gap={32}>
              <Datepicker
                fullWidth
                time
                required
                placeholder="Selecione a data da venda"
                name="date_sale"
                label="Data da venda"
                control={control}
              />

              <img src={EarningsIcon} width="400" />
            </Box>

            <input type="submit" hidden />
          </form>
        }
      />

      <Dialog
        title="Marcar perda"
        open={openLoss}
        onSubmit={handleSubmit(handleUpdateDeal)}
        onOpenChange={onOpenChange}
        content={
          <form onSubmit={handleSubmit(handleUpdateDeal)}>
            <Box flexDirection="column" alignItems="center" gap={32}>
              <Select
                label="Motivo de perda"
                fullWidth
                required
                control={control}
                name="loss_id"
                options={loss.map((l) => ({
                  label: l.name,
                  value: l.id,
                }))}
              />

              <Datepicker
                time
                required
                fullWidth
                placeholder="Selecione a data da perda"
                name="date_loss"
                label="Data da perda"
                control={control}
              />

              <img src={SeedingIcon} width="320" />

              <input type="submit" hidden />
            </Box>
          </form>
        }
      />
      <Dialog
        title="Voltar a negociar"
        open={openResetResult}
        onOpenChange={onOpenChange}
        content={
          <form onSubmit={handleSubmit(resetResult)}>
            <Box flexDirection="column" gap={16}>
              <Typography noWrap variant="body">
                Ao confirmar a negociação voltará para o status aberto.
              </Typography>

              <Spacer height={32} />
              <Box gap={8}>
                <Button
                  onClick={pressEscKey}
                  variant="outline"
                  type="button"
                  text="Cancelar"
                />
                <Button
                  type="submit"
                  text={loading ? 'Carregando...' : 'Voltar a negociar'}
                />
              </Box>
            </Box>
          </form>
        }
      />
    </>
  );
};

export default ChangeResultTemplate;
