import { RemoteListRolesUseCase } from 'data/usecases/role/list-roles-use-case';
import { ListRolesUseCase } from 'domain/usecases/role/list-roles-use-case';
import { AxiosProvider } from 'infra/http';
import { useToken } from 'main/hooks/usetoken';

export const ListRolesFactory = (): ListRolesUseCase => {
  const { token = '' } = useToken();
  const axios = new AxiosProvider({
    token,
  });

  return new RemoteListRolesUseCase(
    `${process.env.REACT_APP_API_BASE_URL}/roles`,
    axios
  );
};
