import { ICampaign } from './ICampaign';
import { IChannel } from './IChannel';
import { ICompany } from './ICompany';
import { IContact } from './IContact';
import { IFieldRegistry } from './IFieldRegistry';
import { IFunnel } from './IFunnel';
import { ISale } from './ISale';
import { ITag } from './ITag';
import { IUser } from './IUser';

export enum DealResult {
  Open = 0,
  Won = 1,
  Lost = 2,
}
export interface IDeal {
  id: string;
  name: string;
  tenant_id: string;
  funnel_id: string;
  created_at: string;
  updated_at: string;
  value?: number;
  contacts?: IContact[];
  companies?: ICompany[];
  tags?: ITag[];
  result?: DealResult;
  user_id?: string;
  user?: IUser;
  campaign?: ICampaign;
  description?: string;
  channel?: IChannel;
  channel_id?: string;
  funnel?: IFunnel;
  payment?: 'unique' | 'recurrent';
  date_sale?: Date;
  date_lossed?: Date;
  closing_date?: Date;
  custom_fields?: IFieldRegistry[];
  sales?: ISale[];
}
