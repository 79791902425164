import { useEffect, useState } from 'react';
import { IRole } from 'domain/entity/IRole';
import { ListRolesFactory } from 'main/factories/usecases/roles/list-roles-use-case-factory';

type UseGroupsType = {
  groups: IRole[] | [];
  fetchGroups: () => Promise<void>;
  loading: boolean;
};

export const useGroups = (): UseGroupsType => {
  const [groups, setGroups] = useState<IRole[] | []>([]);
  const [loading, setLoading] = useState(false);

  async function fetchGroups(): Promise<void> {
    try {
      setLoading(true);
      const data = await ListRolesFactory().execute();

      setGroups(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchGroups();
  }, []);

  return { groups, fetchGroups, loading };
};
