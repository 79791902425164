import { RemoteSaveActivityUseCase } from 'data/usecases/activity/remote-save-activity';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeSaveActivityUseCase = (
  activityId?: string,
  preventToast?: boolean
): RemoteSaveActivityUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteSaveActivityUseCase(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/activities/${activityId || ''}`,
    !preventToast ? toastify : undefined
  );
};
