import { ActivityType } from 'domain/entity/IActivity';

export enum IconColors {
  dark = 'dark',
  blue = 'blue',
  green = 'green',
  gray = 'gray',
  red = 'red',
  white = 'white',
  orange = 'orange',
  violet = 'violet',
  teal = 'teal',
  systemGreen = 'systemGreen',
  systemRed = 'systemRed',
}

export function renderIcon(type: ActivityType): string {
  switch (type) {
    case ActivityType.CALL:
      return 'Call20Regular';
    case ActivityType.MESSAGE:
      return 'Chat20Regular';
    case ActivityType.EMAIL:
      return 'Mail20Regular';
    case ActivityType.MEETING:
      return 'BuildingPeople20Regular';
    case ActivityType.PROPOSAL:
      return 'Handshake20Regular';
    case ActivityType.NOTE:
      return 'Notepad20Regular';
    case ActivityType.SALE:
      return 'ThumbLike20Regular';
    case ActivityType.LOSS:
      return 'ThumbDislike20Regular';

    default:
      return 'DefaultIcon';
  }
}

export function RenderIconColor(type: ActivityType): any {
  switch (type) {
    case ActivityType.CALL:
      return IconColors.blue;
    case ActivityType.MESSAGE:
      return IconColors.red;
    case ActivityType.EMAIL:
      return IconColors.violet;
    case ActivityType.MEETING:
      return IconColors.green;
    case ActivityType.PROPOSAL:
      return IconColors.teal;
    case ActivityType.NOTE:
      return IconColors.orange;
    case ActivityType.SALE:
      return IconColors.systemGreen;
    case ActivityType.LOSS:
      return IconColors.systemRed;

    default:
      return IconColors.gray;
  }
}
