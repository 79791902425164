import { RemoteSaveRoleUseCase } from 'data/usecases/role/save-role-use-case';
import { SaveRoleUseCase } from 'domain/usecases/role/save-role-use-case';
import { AxiosProvider } from 'infra/http';
import { useToken } from 'main/hooks/usetoken';

export const SaveRolesUseCaseFactory = (roleId?: string): SaveRoleUseCase => {
  const { token = '' } = useToken();
  const axios = new AxiosProvider({
    token,
  });

  return new RemoteSaveRoleUseCase(
    `${process.env.REACT_APP_API_BASE_URL}/roles/${roleId || ''}`,
    axios
  );
};
