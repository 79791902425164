import React, { ReactElement, useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Calendar,
  Card,
  Datepicker,
  KPICard,
  PopOver,
  Table,
  Typography,
} from 'mino-ui';

import { ReactSVG } from 'react-svg';
import * as S from './styles';
import DealIcon from './icons/deal.svg';
import NewDealIcon from './icons/new-deals.svg';
import EarnedDealIcon from './icons/earned-deals.svg';
import LossedDealIcon from './icons/lossed-deals.svg';
import FilterIcon from './icons/filter.svg';
import DealListIcon from 'presentation/assets/icons/deal.svg';
import { IDeal } from 'domain/entity/IDeal';
import { IActivity } from 'domain/entity/IActivity';
import { Link, useNavigate } from 'react-router-dom';
import {
  NewDealsReportRequestDTO,
  NewDealsReportResponseDTO,
} from 'domain/usecases/report/new-deals-report-use-case';
import {
  formatToReal,
  formatToRealRelative,
} from 'main/helpers/format-to-real';
import { useForm } from 'react-hook-form';
import { pressEscKey } from 'main/helpers/scape-key-esc';
import { SessionContext } from 'presentation/layout/contexts/SessionContext';
import ScheduleActivityDialog, {
  ScheduleActivityForm,
} from 'presentation/shared/templates/Activitites/dialogs/ScheduleActivityDialog';
import { ActivityFilterParams } from 'domain/usecases/activity/list-activities-use-case';

interface HomePageProps {
  deals: IDeal[];
  activities: IActivity[];
  loading: boolean;
  newDeals?: NewDealsReportResponseDTO;
  earnedDeals?: NewDealsReportResponseDTO;
  lossedDeals?: NewDealsReportResponseDTO;
  filterReport: NewDealsReportRequestDTO;
  setFilterReport: (filter: NewDealsReportRequestDTO) => void;
  fetchData: (params: ActivityFilterParams) => void;
}

type FilterForm = {
  startDate: Date;
  endDate: Date;
  userContext: 'my' | 'all';
};

const HomePage = (props: HomePageProps): ReactElement => {
  const { session } = useContext(SessionContext);

  const {
    activities,
    deals,
    newDeals,
    earnedDeals,
    lossedDeals,
    loading,
    setFilterReport,
    filterReport,
    fetchData,
  } = props;

  const [activityToUpdate, setActivityToUpdate] =
    useState<ScheduleActivityForm>();
  const [IsDrawerOpen, setIsDrawerOpen] = useState(false);
  const [initialStartDate, setInitialStartDate] = useState<Date>();
  const [initialEndDate, setInitialEndDate] = useState<Date>();

  useEffect(() => {
    async function subscribeAgain() {
      await window.OneSignal.setSubscription(true);
    }

    subscribeAgain();
  }, []);

  const { control, getValues, handleSubmit } = useForm<FilterForm>({
    defaultValues: {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(),
      userContext: 'my',
    },
  });

  const navigate = useNavigate();

  return (
    <Box boxMain gap={16}>
      {session.subscription?.status === 'incomplete' && (
        <Box
          alignItems="center"
          gap={8}
          style={{
            backgroundColor: '#f1e6fa',
            color: '#ad6be1',
            width: '100%',
            padding: 16,
            fontSize: 12,
            borderRadius: 8,
          }}
        >
          Só falta registrar os dados de pagamento para continuar usando o
          TaskFlow CRM.
          <a
            target="_blank"
            href="https://billing.stripe.com/p/login/3csdSS4xVcudbteeUU"
            rel="noreferrer"
          >
            Clique aqui para registrar
          </a>
        </Box>
      )}

      {session.subscription?.status === 'trialing' &&
        session.subscription?.trial_end && (
          <Box
            alignItems="center"
            gap={8}
            style={{
              backgroundColor: '#f1e6fa',
              color: '#ad6be1',
              width: '100%',
              padding: 16,
              borderRadius: 8,
              fontSize: 12,
            }}
          >
            Sua versão de teste do TaskFlow CRM termina em{' '}
            {new Date(session.subscription.trial_end).toLocaleDateString()}.
            <br />
            <a
              target="_blank"
              href="https://billing.stripe.com/p/login/3csdSS4xVcudbteeUU"
              rel="noreferrer"
            >
              Clique aqui para fazer o upgrade
            </a>
          </Box>
        )}

      {session.subscription?.status === 'past_due' ||
      session.subscription?.status === 'unpaid' ||
      session.subscription?.status === 'canceled' ? (
        <Box
          alignItems="center"
          gap={8}
          style={{
            backgroundColor: '#f1e6fa',
            color: '#ad6be1',
            width: '100%',
            padding: 16,
            borderRadius: 8,
            fontSize: 12,
          }}
        >
          Seu plano está com problemas de pagamento, por favor, regularize para
          continuar utilizando o TaskFlow CRM.
          <a
            target="_blank"
            href="https://billing.stripe.com/p/login/3csdSS4xVcudbteeUU"
            rel="noreferrer"
          >
            Clique aqui para regularizar
          </a>
        </Box>
      ) : null}

      <Box>
        <PopOver
          align="start"
          trigger={
            <Button
              size="small"
              variant="ghostSecondary"
              icon={FilterIcon}
              type="button"
              text={`De: ${
                filterReport.startDate
                  ? new Date(filterReport.startDate).toLocaleDateString()
                  : ''
              } - Até: ${
                filterReport.endDate
                  ? new Date(filterReport.endDate).toLocaleDateString()
                  : ''
              }`}
            />
          }
          content={
            <Card>
              <form
                onSubmit={handleSubmit((d) => {
                  setFilterReport({
                    ...filterReport,
                    ...d,
                  });
                  pressEscKey();
                })}
              >
                <Box flexDirectionMobile="column" alignItems="flex-end" gap={8}>
                  <Datepicker
                    label="Data Inicial"
                    startDate={getValues('startDate')}
                    control={control}
                    name="startDate"
                    placeholder="De"
                  />
                  <Datepicker
                    label="Data Final"
                    startDate={getValues('endDate')}
                    control={control}
                    name="endDate"
                    placeholder="Até"
                  />
                  <Button
                    variant="ghost"
                    text=""
                    type="submit"
                    icon={FilterIcon}
                  />
                </Box>
              </form>
            </Card>
          }
        />
      </Box>
      <Box flexDirectionMobile="column" gap={16}>
        <Card direction="column" align="center" justifyContent="center">
          <Typography noWrap fill align="center" fontSize={20} variant="body">
            🌤️Seja bem vindo(a),
          </Typography>

          <Typography
            noWrap
            fill
            weight="bold"
            align="center"
            fontSize={20}
            variant="body"
            color="gray"
          >
            {session.username}!
          </Typography>
        </Card>
        <KPICard
          titleColorStyle="primary"
          full
          icon={NewDealIcon}
          title="Novos Negócios"
          secondValue={newDeals?.deals_count.toString() || '0'}
          value={formatToRealRelative(newDeals?.deals_value || 0)}
          loading={loading}
        />
        <KPICard
          titleColorStyle="green"
          full
          icon={EarnedDealIcon}
          title="Negócios Ganhos"
          secondValue={earnedDeals?.deals_count.toString() || '0'}
          value={formatToRealRelative(earnedDeals?.deals_value || 0)}
          loading={loading}
        />
        <KPICard
          full
          titleColorStyle="red"
          icon={LossedDealIcon}
          title="Negócios Perdidos"
          secondValue={lossedDeals?.deals_count.toString() || '0'}
          value={formatToRealRelative(lossedDeals?.deals_value || 0)}
          loading={loading}
        />
      </Box>
      <S.WrapCards>
        <Card gap={16} fillHeight direction="column">
          <Box justifyContent="space-between" alignItems="center" gap={8}>
            <Box alignItems="center" gap={8}>
              <ReactSVG className="force-align-center" src={DealIcon} />
              <Typography
                fill
                fontSize={18}
                color="primary"
                weight="bold"
                variant="body"
              >
                Negócios Recentes
              </Typography>
            </Box>

            <Button
              size="small"
              text="Ver todos"
              variant="ghost"
              onClick={() => navigate('/deals')}
            />
          </Box>

          <Card>
            <Table
              compact
              columns={[
                {
                  field: 'name',
                  headerName: 'Negócio',
                  align: 'left',
                },
                {
                  field: 'funnel',
                  headerName: 'Etapa',
                  align: 'right',
                },
                {
                  field: 'value',
                  headerName: 'Valor',
                  align: 'right',
                },
              ]}
              hasOutline
              rows={deals.map((d) => ({
                id: d.id,
                name: (
                  <Link to={`/deals/${d.id}`}>
                    <Box alignItems="center" gap={16}>
                      <ReactSVG src={DealListIcon} />

                      <Typography
                        ellipsis
                        weight="bold"
                        variant="body"
                        color="dark"
                      >
                        {d.name}
                      </Typography>
                    </Box>
                  </Link>
                ),
                value: (
                  <Typography
                    align="right"
                    weight="bold"
                    variant="body"
                    color="gray"
                    fill
                  >
                    {formatToReal(d.value || 0)}
                  </Typography>
                ),
                funnel: (
                  <Typography
                    align="right"
                    fill
                    weight="bold"
                    variant="body"
                    color="gray"
                  >
                    {d.funnel?.name}
                  </Typography>
                ),
              }))}
            />
          </Card>
        </Card>

        <Card direction="column">
          <ScheduleActivityDialog
            key={activityToUpdate?.id}
            trigger={<></>}
            open={IsDrawerOpen}
            setOpen={(state) => {
              setIsDrawerOpen(state);
              if (!state) {
                setActivityToUpdate(undefined);
              }
            }}
            initialStart={initialStartDate}
            initialEnd={initialEndDate}
            activity={activityToUpdate}
            callback={() => {
              fetchData({
                start: filterReport.startDate,
                end: filterReport.endDate,
                user_id: session.id,
              });
              setIsDrawerOpen(false);
            }}
          />

          <Calendar
            handleSelect={(arg) => {
              setIsDrawerOpen(true);
              setInitialStartDate?.(arg.start);
              setInitialEndDate?.(arg.end);
            }}
            events={activities.map((ac) => ({
              start: ac.start,
              end: ac.end,
              allDay: ac.allDay,
              title: ac.title,
            }))}
          />
        </Card>
      </S.WrapCards>
    </Box>
  );
};

export default HomePage;
