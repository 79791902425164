import React, { ReactElement, useContext, useState } from 'react';
import { Dropdown, Box, Dialog, Input, Select } from 'mino-ui';
import { useForm } from 'react-hook-form';
import { CreateDealRequest } from 'domain/usecases/deal/create-deal-use-case';
import { useChannels } from 'main/hooks/useChannel';
import { makeCreateDealUseCase } from 'main/factories/usecases';
import { useNavigate } from 'react-router';
import { useDebouncedEffect } from 'presentation/hooks/useDebouncedEffect';
import { useCustomFields } from 'main/hooks/useCustomFields';
import Briefcase from './icons/Briefcase.svg';
import * as S from './styles';
import { useUsers } from 'main/hooks/useUsers';
import { useFunnels } from 'main/hooks/useFunnels';
import { useTags } from 'main/hooks/useTags';
import { IContact } from 'domain/entity/IContact';

import { makeUpdateContactUseCase } from 'main/factories/usecases/contact/update-contact-factory';
import {
  CreateSaleRequest,
  CreateSaleResponse,
} from 'domain/usecases/sales/create-sale-use-case';
import { makeRemoteCreateSale } from 'main/factories/usecases/sales/create-sale-factory';
import { SessionContext } from 'presentation/layout/contexts/SessionContext';
import { DealContact } from './atoms/DealContact';
import { DealSales } from './atoms/DealSales';

export type CreateDealDialogProps = {
  open?: boolean;
  setOpen?: (open: boolean) => void;
  callback?: () => void;
  trigger?: ReactElement;
};

export type SaleProps = {
  product_id: string;
  product_name: string;
  product_price?: string;
  deal_id: string;
  quantity: number;
  discount?: string;
  total: string;
};

export const CreateDealDialog = (
  props: CreateDealDialogProps
): ReactElement => {
  const { open, setOpen, callback, trigger } = props;

  const [loading, setLoading] = useState(false);
  const [userSearch, setUserSearch] = useState('');

  const [dealValue, setDealValue] = useState<number>(0);
  const [sales, setSales] = useState<CreateSaleResponse[]>([]);

  const [selectedContact, setSelectedContact] = useState<IContact>();

  const { channels } = useChannels();
  const { users, fetchUsers } = useUsers();
  const { funnels } = useFunnels();
  const { tags } = useTags();

  const { session } = useContext(SessionContext);

  const navigate = useNavigate();

  const { custom_fields } = useCustomFields('deal');

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm<CreateDealRequest>({
    defaultValues: {
      payment: undefined,
      value: undefined,
      user_id: session?.id,
    },
  });

  async function createDeal(data: CreateDealRequest) {
    try {
      setLoading(true);
      if (selectedContact != undefined) {
        await quickUpdateContact(selectedContact);
      }

      const custom_fields_inputs: CreateDealRequest['custom_fields'] = {};

      for (const field of custom_fields) {
        if (data[`cf_${field.id}`]) {
          custom_fields_inputs['cf_' + field.id] = data[`cf_${field.id}`];
        }
      }

      const result = await makeCreateDealUseCase().execute({
        ...data,
        attach_contacts: selectedContact?.id
          ? [selectedContact?.id]
          : undefined,
        attach_companies: selectedContact?.company_id
          ? [selectedContact?.company_id]
          : undefined,
        attach_tags: data?.attach_tags,
        value: dealValue,
        funnel_id:
          data.funnel_id || funnels?.sort((a, b) => a.order - b.order)[0].id,
        custom_fields: custom_fields_inputs,
      });

      if (result.id && sales.length > 0) {
        const salePromises = sales.map(
          (saleData) =>
            saleData.product_id !== '0' &&
            createSale({
              id: saleData.id,
              deal_id: result.id,
              product_id: saleData.product_id,
              discount: saleData.discount,
              quantity: saleData.quantity,
              total: saleData.total,
            })
        );

        await Promise.all(salePromises);
      }

      navigate(`/deals/${result.id}`);
    } catch (error) {
      console.log(error);
    } finally {
      callback?.();
      setLoading(false);
    }
  }

  async function quickUpdateContact(contact: IContact) {
    const { id, name, email, phone, company_id } = contact;

    try {
      await makeUpdateContactUseCase(id).execute({
        name: name,
        company_id: company_id,
        email: email,
        phone: phone,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function createSale(data: CreateSaleRequest) {
    try {
      await makeRemoteCreateSale().execute(data);
    } catch (error) {
      console.log(error);
    }
  }

  useDebouncedEffect(
    () => {
      if (userSearch.length > 0) {
        fetchUsers(userSearch);
      }
    },
    [userSearch],
    300
  );

  return (
    <Dialog
      maxWidth={750}
      title="Novo Negócio"
      headerIcon={Briefcase}
      loading={loading}
      open={open}
      onOpenChange={(state) => {
        !state && reset?.();
        setOpen?.(state);
      }}
      trigger={trigger}
      onSubmit={handleSubmit(createDeal)}
      content={
        <Box flexDirection="column">
          <form onSubmit={handleSubmit(createDeal)}>
            <Box flexDirection="column" gap={16}>
              <div>
                {users.length && session?.id ? (
                  <Dropdown
                    initialSelectedItem={{
                      label: session?.username,
                      value: session?.id,
                      avatar: session?.avatar,
                    }}
                    onSearch={(v) => setUserSearch(v)}
                    label="Responsável"
                    align="start"
                    searchable
                    hasAvatar
                    onChange={(v) => {
                      if (v) setValue('user_id', v.toString());
                    }}
                    placeholder="Selecionar responsável"
                    items={users.map((u) => ({
                      value: u.id,
                      label: u.name,
                      avatar: u.avatar,
                    }))}
                  />
                ) : null}
              </div>

              <div>
                {funnels.length > 0 ? (
                  <Dropdown
                    label="Etapa do funil"
                    placeholder="Selecionar etapa"
                    initialSelectedItem={{
                      label: funnels.sort((a, b) => a.order - b.order)[0].name,
                      value: funnels.sort((a, b) => a.order - b.order)[0].id,
                    }}
                    onChange={(v) => {
                      if (v) {
                        setValue('funnel_id', v.toString());
                      }
                    }}
                    items={funnels.map((c) => ({
                      label: c.name,
                      value: c.id,
                    }))}
                  />
                ) : null}
              </div>

              <S.Separator />

              <Input
                label="Descrição"
                placeholder="Ex: Venda de curso"
                register={register}
                autoFocus
                name="name"
                type="text"
                validations={{
                  required: true,
                }}
                errors={errors}
                full
              />

              <Select
                control={control}
                name="channel_id"
                placeholder="Selecione a origem"
                label="Origem"
                options={channels.map((c) => ({
                  label: c.name,
                  value: c.id,
                }))}
              />

              <Select
                control={control}
                name="attach_tags"
                placeholder="Selecione a tag"
                multi
                label="Tags"
                options={tags.map((c) => ({
                  label: c.name,
                  value: c.id,
                }))}
              />

              {custom_fields.map((field) => (
                <Input
                  key={field.id}
                  register={register}
                  type={'text'}
                  placeholder={field.name}
                  name={`cf_${field.id}`}
                  label={field.name}
                  errors={errors}
                />
              ))}
              <S.Separator />
            </Box>

            <DealContact setContact={setSelectedContact} />

            <S.Separator />

            <DealSales
              sales={sales}
              setSales={setSales}
              setDealValues={setDealValue}
            />
            <input type="submit" hidden />
          </form>
        </Box>
      }
    />
  );
};
