import { RemoteForgotPasswordUseCase } from 'data/usecases/sessions/remote-forgot-password';
import { ForgotPasswordUseCase } from 'domain/usecases/session/forgot-password';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeForgotPassword = (): ForgotPasswordUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteForgotPasswordUseCase(
    `${process.env.REACT_APP_API_BASE_URL}/session/reset-password`,
    axios,
    toastify
  );
};
