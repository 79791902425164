import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { IContact } from 'domain/entity/IContact';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import { GetSingleContactUseCase } from 'domain/usecases/contact/get-contact-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteGetContact implements GetSingleContactUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(): Promise<IContact> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      method: 'get',
      url: this.url,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Tenant: tenant,
      },
    });

    const contact = response.body;
    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return {
          phone: contact.phone_number,
          ...contact,
        };

      case HttpStatusCode.unauthorized:
        this.toastService.show('Não autorizado', {
          type: 'error',
        });
        throw new NotAuthorizedError('Unauthorized');
      default:
        this.toastService.show('Erro no servidor', {
          type: 'error',
        });
        throw new UnexpectedError('Unexpected error');
    }
  }
}
