import { RemoteListHomeDeals } from 'data/usecases/home/remote-list-home-deals';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeHomeDealsUseCase = (): RemoteListHomeDeals => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteListHomeDeals(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/product-home`,
    toastify
  );
};
