import React, { createRef, ReactElement, useEffect, useState } from 'react';
import { Box, Drawer, Input, Select } from 'mino-ui';
import { RequestPayloadTags } from 'domain/usecases/tag/save-tag-use-case';
import { useForm } from 'react-hook-form';
import { useTags } from 'main/hooks/useTags';

interface ManageDrawerProps {
  callback: () => void;
  updatePayload?: RequestPayloadTags;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export const ManageDrawer = (props: ManageDrawerProps): ReactElement => {
  const { callback, isOpen, setIsOpen, updatePayload } = props;

  const { updateTag } = useTags();

  const initialPayload: RequestPayloadTags = {
    id: '',
    name: '',
    color: '',
  };

  function handleSelectChange(value: string): void {
    setValue('color', value);
  }

  const [loading, setLoading] = useState(false);

  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
  } = useForm<RequestPayloadTags>({
    defaultValues: {
      id: updatePayload?.id,
      name: updatePayload?.name,
      color: updatePayload?.color,
    },
  });

  function resetForm() {
    setValue('id', initialPayload.id);
    setValue('name', initialPayload.name);
    setValue('color', initialPayload.color);
  }

  function updateForm() {
    if (updatePayload) {
      setValue('id', updatePayload.id);
      setValue('name', updatePayload.name);
      setValue('color', updatePayload.color);
    }
  }

  async function saveTag(data: RequestPayloadTags) {
    try {
      setLoading(true);

      await updateTag({
        id: data.id || '',
        name: data.name,
        color: data.color,
      });

      resetForm();
      callback();
      setIsOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (updatePayload) {
      updateForm();
    }
  }, [updatePayload]);

  const submitRef = createRef<HTMLInputElement>();

  return (
    <Drawer
      submitText={'Salvar'}
      title={'Atualizar Tag'}
      onClose={() => {
        setIsOpen(false);
        resetForm();
      }}
      onSubmit={() => submitRef.current?.click()}
      isOpen={isOpen}
      loadingSubmit={loading}
    >
      <form onSubmit={handleSubmit(saveTag)}>
        <Box gap={8} flexDirection="column">
          <Input hidden type="text" name="id" register={register} />

          <Input
            label="Nome"
            placeholder="Ex: Cliente"
            name="name"
            register={register}
            errors={errors}
            validations={{
              required: true,
            }}
          />
          <Select
            fullWidth
            defaultValue={updatePayload?.color}
            onChange={(v) => v && handleSelectChange(v.value)}
            options={[
              {
                value: 'blue',
                label: 'Azul',
              },
              {
                value: 'red',
                label: 'Vermelho',
              },
              {
                value: 'green',
                label: 'Verde',
              },
              {
                value: 'yellow',
                label: 'Amarelo',
              },
              {
                value: 'violet',
                label: 'Roxo',
              },
              {
                value: 'orange',
                label: 'Laranja',
              },
              {
                value: 'teal',
                label: 'Azul claro',
              },
              {
                value: 'gray',
                label: 'Cinza',
              },
            ]}
            placeholder="Selecione a cor"
            label="Cor"
          />

          <input ref={submitRef} hidden type="submit" />
        </Box>
      </form>
    </Drawer>
  );
};
