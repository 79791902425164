import { RemoteListLoss } from 'data/usecases/loss/remote-list-loss';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeListLossUseCase = (): RemoteListLoss => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteListLoss(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/loss-reasons`,
    toastify
  );
};
