import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Box, Button, Input, Select } from 'mino-ui';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useToken } from 'main/hooks/usetoken';
import { SessionContext } from 'presentation/layout/contexts/SessionContext';
import { makeToastify } from 'main/factories/notification';

interface ZenviaForm {
  active: string;
  access_token: string;
}

export const IntegrateZenvia = (): ReactElement => {
  const { session } = useContext(SessionContext);

  const [loading, setLoading] = useState(false);

  const updateMode = session?.integrations?.zenvia;
  const toastify = makeToastify();

  async function submitIntegrateZenvia(data: ZenviaForm): Promise<void> {
    const token = useToken();

    const isUpdate = updateMode.access_token ? true : false;
    try {
      setLoading(true);
      await axios(
        `${process.env.REACT_APP_API_BASE_URL}/zenvia-token${
          isUpdate ? `/${updateMode.id}` : ''
        }`,
        {
          method: isUpdate ? 'put' : 'post',
          headers: {
            Authorization: `Bearer ${token.token}`,
            Tenant: token.tenant || '',
          },
          data: data,
        }
      );

      toastify.show('Salvo com sucesso!', {
        type: 'success',
      });

      window.location.href = '/config?active=integrations';
    } catch (error) {
      console.log(error);
      toastify.show('Algo deu errado no servidor!', {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    setValue,
  } = useForm<ZenviaForm>();

  useEffect(() => {
    setValue('active', String(updateMode.active));
    setValue('access_token', updateMode.access_token);
  }, [updateMode]);

  return (
    <form onSubmit={handleSubmit(submitIntegrateZenvia)}>
      <Box flexDirection="column" gap={16}>
        <Box width="100px">
          <Select
            name="active"
            label="Ativo"
            required
            control={control}
            options={[
              {
                value: '1',
                label: 'Sim',
              },
              {
                value: '0',
                label: 'Não',
              },
            ]}
          />
        </Box>

        <Input
          label="Zenvia Token"
          name="access_token"
          placeholder="Insira seu token"
          register={register}
          errors={errors}
          validations={{
            required: true,
            minLength: 6,
          }}
        />

        <Button
          type="submit"
          text={loading ? 'Salvando' : 'Salvar'}
          disabled={loading}
        />
      </Box>
    </form>
  );
};
