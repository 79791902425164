import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as S from './styles';
import {
  Box,
  Typography,
  Card,
  Spacer,
  SelectNative,
  Button,
  Dialog,
  Icon,
  Dropdown,
  Badge,
} from 'mino-ui';
import ActionButton from './components/ActionButton/component';
import ContactCard from './components/ContactCard/component';
import DealCard from './components/DealCard/component';
import Briefcase from './icons/Briefcase.svg';
import Money from './icons/money.svg';
import EditIcon from './icons/edit.svg';
import MenuIcon from './icons/menu-icon.svg';
import Ilustration from './icons/transfer-ilustration.svg';
import { ReactSVG } from 'react-svg';
import { DealResult, IDeal } from 'domain/entity/IDeal';
import { SingleDealContext } from '../contexts/SingleDealContext';
import EditDealDrawer from './EditDealDrawer/component';
import ActivitiesTemplate from 'presentation/shared/templates/Activitites/template';
import { useFunnels } from 'main/hooks/useFunnels';
import { makeUpdateDealUseCase } from 'main/factories/usecases';
import { ContactDrawer } from './ContactDrawer';
import CompaniesCard from './components/CompanyCard/component';
import { CompanyDrawer } from './CompanyDrawer/component';
import { SingleEntityPageSkeleton } from 'presentation/shared/templates/Skeletons/SingleEntityPageSkeleton';
import {
  EmailsModel,
  PhoneNumbersModel,
} from 'presentation/pages/Activity/context/ActivityContext';
import { useNavigate } from 'react-router';
import { makeDeleteDealUseCase } from 'main/factories/usecases/deal/delete-deal-factory';
import { pressEscKey } from 'main/helpers/scape-key-esc';
import { formatToReal } from 'main/helpers';
import { UpdateDealRequest } from 'domain/usecases/deal/update-deal-use-case';
import AddContactDialog from 'presentation/shared/templates/Contacts/addContactDialog';
import AddCompanyDialog from 'presentation/shared/templates/Companies/addCompanyDialog';
import TagsCard from './components/TagsCard/component';
import AttachTagsDialog from './components/AttachTags/component';
import { useCustomFields } from 'main/hooks/useCustomFields';
import { SaleDialog } from './components/SaleDialog';
import { CreateSaleRequest } from 'domain/usecases/sales/create-sale-use-case';
import { makeRemoteCreateSale } from 'main/factories/usecases/sales/create-sale-factory';
import { makeRemoveSale } from 'main/factories/usecases/sales/remove-sale-factory';

interface PageProps {
  deal: IDeal;
  loading: boolean;
  callback: () => void;
  fetchDeal: () => void;
}

export enum IconColors {
  systemRed = 'systemRed',
  blue = 'blue',
}

const SingleDealPage = (props: PageProps): ReactElement => {
  const { deal, callback, fetchDeal, loading } = props;

  const { funnels } = useFunnels();

  const [salesDialogOpen, setSalesDialogOpen] = useState(false);
  const [editDealOpen, setEditDeal] = useState(false);
  const [contactDrawerOpen, setContactDrawerOpen] = useState(false);
  const [addContactDialogOpen, setAddContactDialogOpen] = useState(false);
  const [serviceDrawerOpen, setServiceDrawerOpen] = useState(false);
  const [companyDrawerOpen, setCompanyDrawerOpen] = useState(false);
  const [addCompanyDialogOpen, setAddCompanyDialogOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [transferDataDialog, setTransferDataDialog] = useState(false);
  const [transferLoading, setTransferLoading] = useState(false);
  const [addTagsDialog, setAddTagsDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [textWidth, setTextWidth] = useState(0);

  const textContent = useRef<HTMLDivElement>(null);

  enum IconNames {
    PersonSwap20Regular = 'PersonSwap20Regular',
  }
  interface GroupListProps {
    label: string;
    value: string;
    icon: string;
    iconColor?: IconColors;
  }

  const navigate = useNavigate();

  const GroupListArray: GroupListProps[] = [
    {
      label: 'Editar',
      value: 'edit',
      icon: EditIcon,
    },
    {
      label: 'Excluir',
      value: 'delete',
      icon: EditIcon,
      iconColor: IconColors.systemRed,
    },
  ];

  async function updateFunnel(funnelId: string) {
    if (!deal) return;
    try {
      await makeUpdateDealUseCase(deal.id).execute({
        funnel_id: funnelId,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteDeal(DealID: string) {
    try {
      setDeleteLoading(true);
      const res = await makeDeleteDealUseCase(DealID).execute();
      if (res.success) {
        navigate('/deals');
      }
      pressEscKey();
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteLoading(false);
    }
  }

  const phoneNumbers: PhoneNumbersModel[] = [];
  const emails: EmailsModel[] = [];

  if (deal?.contacts) {
    deal.contacts.forEach((contact) => {
      if (contact.phone) {
        phoneNumbers.push({
          entity: 'contact',
          name: contact.name,
          phone: contact.phone,
        });
      }
      if (contact.email) {
        emails.push({
          entity: 'contact',
          name: contact.name,
          email: contact.email,
        });
      }
    });
  }

  if (deal?.companies) {
    deal.companies.forEach((company) => {
      if (company.phone_number) {
        phoneNumbers.push({
          entity: 'company',
          name: company.name,
          phone: company.phone_number,
        });
      }
      if (company.email) {
        emails.push({
          entity: 'company',
          name: company.name,
          email: company.email,
        });
      }
    });
  }

  async function updateDeal(data: UpdateDealRequest) {
    try {
      setTransferLoading(true);
      await makeUpdateDealUseCase(`${deal?.id}`).execute(data);
      setTransferDataDialog(false);
    } catch (error) {
      console.log(error);
    } finally {
      setTransferLoading(false);
    }
  }

  async function updateSales(newSales: CreateSaleRequest[]) {
    try {
      //remove all initial sales and add the new ones
      if (newSales !== deal.sales) {
        const dealsRemove =
          deal.sales?.map(async (sale) => {
            await removeSales(sale.id);

            return;
          }) || [];

        await Promise.all(dealsRemove);

        const salePromises = newSales.map(
          (saleData) =>
            saleData.product_id !== '0' &&
            makeRemoteCreateSale().execute({
              id: saleData.id,
              deal_id: deal.id,
              product_id: saleData.product_id,
              discount: saleData.discount,
              quantity: saleData.quantity,
              total: saleData.total,
            })
        );
        await Promise.all(salePromises);
      }
    } catch (error) {
      console.error;
    } finally {
      setSalesDialogOpen(false);
      fetchDeal();
    }
  }

  async function removeSales(saleId: string) {
    await makeRemoveSale(saleId).execute();
  }

  const handleResize = useCallback(() => {
    if (textContent.current) {
      setTextWidth(textContent.current.offsetWidth + 48 + 8);
    }
  }, [textContent]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const { custom_fields } = useCustomFields('deal');

  return (
    <SingleDealContext.Provider
      value={{
        editDealOpen,
        setEditDeal,
        contactDrawerOpen,
        setContactDrawerOpen,
        addContactDialogOpen,
        setAddContactDialogOpen,
        serviceDrawerOpen,
        setServiceDrawerOpen,
        companyDrawerOpen,
        setCompanyDrawerOpen,
        addCompanyDialogOpen,
        setAddCompanyDialogOpen,
        fetchDeal,
        transferDataDialog,
        setTransferDataDialog,
        addTagsDialog,
        setAddTagsDialog,
        all_custom_fields: custom_fields,
      }}
    >
      <S.Toolbar result={deal?.result}>
        <S.ToolbarLeftContent>
          <S.IconWrap result={deal?.result}>
            <ReactSVG src={Briefcase} />
          </S.IconWrap>
          <Typography
            variant="body"
            color="dark"
            fontSize={18}
            weight="bold"
            overflowHidden
            width={`calc(100% - ${textWidth}px)`}
          >
            {deal.name}
          </Typography>
        </S.ToolbarLeftContent>

        <S.ToolbarRightContent ref={textContent}>
          <Box>
            <S.SalesTrigger
              onClick={() => setSalesDialogOpen(true)}
              type="button"
            >
              <Icon iconName="ShoppingBag20Regular" color={IconColors.blue} />
              <Typography
                variant="body"
                color="gray"
                fontSize={14}
                weight="regular"
              >
                {deal?.sales && deal?.sales?.length < 1
                  ? 'Adicionar Produto'
                  : deal?.sales
                      ?.filter((_, index) => index <= 2)
                      .map((s) => s?.product?.name)
                      .join(', ')}
              </Typography>

              {deal.sales && deal.sales?.length > 3 ? (
                <Badge value={'+ ' + (deal.sales?.length - 3)} />
              ) : null}
            </S.SalesTrigger>
          </Box>

          <Box justifyContent="center" alignItems="center" gap={16}>
            <Typography variant="body" color="dark" fontSize={16} weight="bold">
              {deal.value && deal.value > 0
                ? formatToReal(deal.value || 0)
                : formatToReal(
                    deal.sales?.reduce((acc, sale) => acc + sale.total, 0) || 0
                  )}
            </Typography>
            {GroupListArray.length > 0 && (
              <Dropdown
                align="end"
                trigger="action-button"
                items={GroupListArray}
                onChange={(value) => {
                  if (value === 'edit') {
                    setEditDeal(true);
                  }
                  if (value === 'delete') {
                    setDeleteDialog(true);
                  }
                }}
              />
            )}
          </Box>
        </S.ToolbarRightContent>
      </S.Toolbar>
      <Box boxMain>
        <S.Container>
          <S.GridArea>
            {loading ? (
              <SingleEntityPageSkeleton />
            ) : (
              <>
                <S.MainContent>
                  {deal.id && (
                    <ActivitiesTemplate
                      context="deal"
                      contextId={deal.id}
                      phoneNumbers={phoneNumbers}
                      emails={emails}
                    />
                  )}
                </S.MainContent>

                <S.RightContent>
                  <Card padding="0">
                    <Box flexDirection="column" width="100%">
                      <S.Header>
                        <Box
                          flexDirectionMobile="row"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                          gap={8}
                        >
                          <ReactSVG src={Money} />
                          <Typography
                            variant="body"
                            color="dark"
                            fontSize={16}
                            weight="bold"
                          >
                            {formatToReal(deal.value || 0)}
                          </Typography>
                        </Box>
                        <Button
                          text=""
                          icon={EditIcon}
                          variant="ghost"
                          size="small"
                          onClick={() => setEditDeal(true)}
                        />
                      </S.Header>
                      <S.DropdownWrap>
                        <ReactSVG src={MenuIcon} />

                        <SelectNative
                          disabled={deal.result != DealResult.Open}
                          placeholder={
                            funnels.find((f) => f.id === deal?.funnel_id)?.name
                          }
                          onValueChange={(v) => updateFunnel(v)}
                          items={funnels.map((funnel) => ({
                            value: funnel.id,
                            textValue: funnel.name,
                          }))}
                        />
                      </S.DropdownWrap>
                    </Box>
                  </Card>
                  <Card>
                    <S.ActionButtonWrap>
                      {deal.result === DealResult.Open ? (
                        <>
                          <ActionButton type="sell" />
                          <ActionButton type="loss" />
                        </>
                      ) : (
                        <ActionButton type={undefined} />
                      )}
                    </S.ActionButtonWrap>
                  </Card>

                  <DealCard
                    deal={{
                      campaign: deal?.campaign?.name,
                      created_at: deal?.created_at,
                      obs: deal?.description,
                      origin: deal?.channel?.name,
                      predict: deal?.closing_date?.toString(),
                      payment: deal?.payment
                        ? deal?.payment === 'unique'
                          ? 'Único'
                          : 'Recorrente'
                        : '',
                      user: deal.user,
                      value: deal.value,
                      id_funnel: deal.funnel_id,
                      custom_fields: deal.custom_fields,
                    }}
                  />

                  {deal?.contacts && <ContactCard contacts={deal?.contacts} />}

                  {deal?.companies && (
                    <CompaniesCard companies={deal?.companies} />
                  )}

                  {deal.tags && <TagsCard tags={deal.tags} />}

                  {/* {deal?.services && (
                    <ProductCard products={deal?.services || []} />
                  )} */}

                  <Spacer height={64} />
                </S.RightContent>
              </>
            )}
          </S.GridArea>
        </S.Container>
      </Box>
      {deal && (
        <EditDealDrawer
          salesValue={
            deal?.sales?.reduce((acc, sale) => acc + sale.total, 0) || 0
          }
          deal={deal}
          isDrawerOpen={editDealOpen}
          setDrawerOpen={setEditDeal}
          callback={callback}
        />
      )}

      {deal?.contacts && (
        <>
          <ContactDrawer
            callback={() => fetchDeal()}
            contacts={deal.contacts}
            isOpen={contactDrawerOpen}
            openOpen={(v) => setContactDrawerOpen(v)}
          />

          <AddContactDialog
            callback={() => fetchDeal()}
            context="deal"
            singleId={deal.id}
            isDialogOpen={addContactDialogOpen}
            setDialogOpen={setAddContactDialogOpen}
          />
        </>
      )}

      {deal?.companies && (
        <>
          <CompanyDrawer
            companies={deal?.companies}
            callback={() => fetchDeal()}
            isOpen={companyDrawerOpen}
            setOpen={(v) => setCompanyDrawerOpen(v)}
          />

          <AddCompanyDialog
            context="deal"
            isDialogOpen={addCompanyDialogOpen}
            setDialogOpen={setAddCompanyDialogOpen}
            callback={() => fetchDeal()}
            singleId={deal.id}
          />
        </>
      )}

      {deal.tags && (
        <AttachTagsDialog callback={callback} deal={deal} tags={deal.tags} />
      )}
      <Dialog
        modal
        title="Transferência de Dados"
        open={transferDataDialog}
        onOpenChange={() => setTransferDataDialog(false)}
        onSubmit={() =>
          updateDeal({ transfer_data: true, user_id: deal.user_id })
        }
        submitText="transferir"
        submitIconName={IconNames.PersonSwap20Regular}
        loading={transferLoading}
        content={
          <Box flexDirection="column" gap={24}>
            <Typography noWrap variant="body" fontSize={12}>
              Você deseja transferir também os Contatos e Empresas relacionados
              para o novo responsável do Negócio recentemente alterado?
            </Typography>
            <ReactSVG src={Ilustration} />
          </Box>
        }
      />
      <Dialog
        open={deleteDialog}
        modal
        title="Atenção!"
        onOpenChange={() => setDeleteDialog(false)}
        content={
          <Box flexDirection="column" gap={32}>
            <Typography noWrap variant="body">
              Tem certeza que deseja excluir esse negócio?
            </Typography>

            <Box gap={8}>
              <Button
                loading={deleteLoading}
                onClick={() => deleteDeal(deal.id)}
                variant="primary"
                text={'Confirmar'}
              />
              <Button
                onClick={() => pressEscKey()}
                variant="outline"
                text="Cancelar"
              />
            </Box>
          </Box>
        }
      />

      {deal ? (
        <SaleDialog
          key={deal.id}
          initialSales={deal.sales || []}
          open={salesDialogOpen}
          onOpenChange={setSalesDialogOpen}
          onSalesChange={(sales) => updateSales(sales)}
        />
      ) : null}
    </SingleDealContext.Provider>
  );
};

export default SingleDealPage;
