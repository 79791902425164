import React, { useContext, useEffect, useState } from 'react';
import { Box, Dialog, Select, Typography } from 'mino-ui';
import { useForm } from 'react-hook-form';
import { useUsers } from 'main/hooks/useUsers';
import DealContext from 'presentation/pages/Deal/contexts/DealContext';
import { makeBulkActionsDeals } from 'main/factories/usecases/deal/bulk-actions-deals-factory';
import { BulkActionsDealsRequest } from 'domain/usecases/deal/bulk-actions-deals-use-case';

interface BulkOwnerProps {
  ids: string[];
}

export enum IconNames {
  Save20Regular = 'Save20Regular',
}
export enum IconColors {
  white = 'white',
}

const BulkOwnerDeals = (props: BulkOwnerProps) => {
  const { ids } = props;
  const { bulkOwner, setBulkOwner, fetchDeals } = useContext(DealContext);
  const { users } = useUsers();
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, setValue, watch, reset } =
    useForm<BulkActionsDealsRequest>();

  const changeOwner = watch('changeOwner');

  async function bulkActionsDeals(data: BulkActionsDealsRequest) {
    try {
      setLoading(true);
      await makeBulkActionsDeals().execute(data);
    } catch (error) {
      console.log(error);
    } finally {
      fetchDeals();
      reset();
      setLoading(false);
      setBulkOwner(false);
    }
  }

  useEffect(() => {
    if (ids) {
      setValue('ids', ids);
    }
  }, [ids, setValue, changeOwner]);

  return (
    <Dialog
      title="Mudança de Responsável"
      open={bulkOwner}
      onOpenChange={() => {
        setBulkOwner(false);
        reset();
      }}
      onSubmit={handleSubmit(bulkActionsDeals)}
      submitIconName={IconNames.Save20Regular}
      submitIconColor={IconColors.white}
      loading={loading}
      content={
        <form onSubmit={handleSubmit(bulkActionsDeals)}>
          <Box flexDirection="column" gap={24}>
            <Typography color="primary" variant="body" fontSize={14}>
              {ids.length} selecionados
            </Typography>
            <Select
              required
              fullWidth
              name="user_id"
              control={control}
              placeholder="Selecione o dono"
              label="Responsável"
              options={users.map((u) => ({
                label: u.name,
                value: u.id,
              }))}
            />
            <Select
              fullWidth
              name="changeOwner"
              control={control}
              placeholder="Selecione o dono"
              label="Alterar dados relacionados"
              defaultValue={'false'}
              options={[
                {
                  label: 'Sim',
                  value: 'true',
                },
                {
                  label: 'Não',
                  value: 'false',
                },
              ]}
            />
          </Box>
          <input type="submit" hidden />
        </form>
      }
    />
  );
};

export default BulkOwnerDeals;
