import { RemoteGetUsersDealsReport } from 'data/usecases/deal/remote-get-users-deals-report-use-case';
import { GetReportUsersDealsUseCase } from 'domain/usecases/deal/report-users-deals.use-case';
import { AxiosProvider } from 'infra/http';
import { useToken } from 'main/hooks/usetoken';

export const makeGetUsersDealsReport = (): GetReportUsersDealsUseCase => {
  const { token = '', tenant = '' } = useToken();
  const axios = new AxiosProvider({ tenant, token });

  return new RemoteGetUsersDealsReport(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/deals/reports/users`
  );
};
