import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Button, RichText } from 'mino-ui';
import { WrapButtons, WrapContent } from '../styles';
import Meeting from '../icons/usersButton.svg';
import Clock from '../icons/clock.svg';
import { ActivityContext } from 'presentation/pages/Activity/context/ActivityContext';
import { useForm } from 'react-hook-form';
import { UploadFile } from './UploadFile';
import { ActivityStatus, ActivityType } from 'domain/entity/IActivity';

type ActivityPayload = {
  title: string;
  description: string;
  start: Date;
  end: Date;
  status: ActivityStatus;
  type: ActivityType;
};
const MeetingTab = (): ReactElement => {
  const [descriptionEditor, setDescriptionEditor] = useState(
    'Registro de Reunião'
  );

  const {
    load,
    handleSubmit,
    setScheduleActivityType,
    setOpenSchedule,
    files,
    setFiles,
  } = useContext(ActivityContext);

  async function submitActivity(data: ActivityPayload) {
    try {
      await handleSubmit({ ...data, description: descriptionEditor });

      setDescriptionEditor('');
    } catch (error) {
      console.error(error);
    }
  }

  const { handleSubmit: handleFormSubmit } = useForm<ActivityPayload>({
    defaultValues: {
      title: 'Reunião',
      type: ActivityType.MEETING,
      description: descriptionEditor,
      start: new Date(),
      end: new Date(),
      status: ActivityStatus.FINISHED,
    },
  });

  useEffect(() => {
    setScheduleActivityType(ActivityType.MEETING);
  }, [setScheduleActivityType]);

  return (
    <WrapContent>
      <form onSubmit={handleFormSubmit(submitActivity)}>
        <RichText
          onChange={(e) => setDescriptionEditor(e)}
          value={descriptionEditor}
        />
        <UploadFile files={files} setFiles={setFiles} />

        <WrapButtons>
          <Button
            size="medium"
            text="Registrar Reunião"
            loading={load}
            icon={Meeting}
            variant="primary"
            type="submit"
            noWrap
          />
          <Button
            size="medium"
            text="Agendar Reunião"
            icon={Clock}
            type="button"
            variant="ghostSecondary"
            onClick={() => setOpenSchedule(true)}
            noWrap
          />
        </WrapButtons>
      </form>
    </WrapContent>
  );
};
export default MeetingTab;
