import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { IUser } from 'domain/entity/IUser';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import {
  SaveUserRequestPayload,
  SaveUserUseCase,
} from 'domain/usecases/user/save-user-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteSaveUser implements SaveUserUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(
    payload: SaveUserRequestPayload,
    type?: 'create' | 'update'
  ): Promise<IUser> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      method: type === 'update' ? 'put' : 'post',
      url: this.url,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Tenant: tenant,
      },
      body: {
        name: payload.name,
        email: payload.email,
        password: payload.password || Math.random().toString(36).slice(-8),
        active: payload.active,
        is_supervisor: false,
        leads_automation: false,
        role_id: payload.role_id,
      },
    });

    const data = response.body;

    switch (response.statusCode) {
      case HttpStatusCode.ok: {
        this.toastService.show(
          type === 'update'
            ? 'Atualizado com sucesso!'
            : 'O Usuário foi criado com sucesso! Enviamos um email de ativação para o usuário. Mas você também pode enviar o link de ativação manualmente.',
          {
            type: 'success',
          }
        );

        return data as IUser;
      }
      case HttpStatusCode.unauthorized:
        this.toastService.show('Unauthorized', {
          type: 'error',
        });
        throw new NotAuthorizedError('Unauthorized');

      case HttpStatusCode.badRequest:
        this.toastService.show('Erro nos campos preenchidos', {
          type: 'error',
        });

        throw new NotAuthorizedError('Bad Request');

      case HttpStatusCode.serverError:
        if (
          response.body.message === 'Esse email já esta em uso, favor alterar'
        ) {
          this.toastService.show('Esse email já está em uso', {
            type: 'error',
          });
          throw new UnexpectedError('Duplicate entry');
        } else {
          this.toastService.show('Erro inesperado no servidor!', {
            type: 'error',
          });

          throw new UnexpectedError('Unexpected error');
        }

      default:
        this.toastService.show('Erro inesperado no servidor!', {
          type: 'error',
        });
        throw new UnexpectedError('Unexpected error');
    }
  }
}
