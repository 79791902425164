import { RemoteImportContactsUseCase } from 'data/usecases/contact/remote-import-contacts';
import { ImportContactsUseCase } from 'domain/usecases/contact/import-contact-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeImportContactsUseCase = (): ImportContactsUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteImportContactsUseCase(
    `${process.env.REACT_APP_API_BASE_URL}/contacts/import`,
    axios,
    toastify
  );
};
