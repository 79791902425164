type useTokenProps = {
  token?: string;
  tenant?: string;
  logout: () => void;
  saveToken: (token: string) => void;
};

function logout() {
  document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

  window.location.href = '/login';
}

function saveTokenToCookie(token) {
  const now = new Date();
  const brazilTimeOffset = -3 * 60; // Brasília time (UTC-3) in minutes
  const localTimeOffset = now.getTimezoneOffset(); // Local time offset in minutes
  const totalOffset = brazilTimeOffset - localTimeOffset; // Total offset in minutes

  // Adjust current time to Brazil time and add 12 hours
  now.setMinutes(now.getMinutes() + totalOffset + 12 * 60);

  const expires = now.toUTCString();
  document.cookie = `token=${encodeURIComponent(
    token
  )}; expires=${expires}; path=/;`;
}

export const useToken = (): useTokenProps => {
  //get token from cookie
  const token = document.cookie
    .split('; ')
    .find((row) => row.startsWith('token='))
    ?.split('=')[1];

  return {
    token: token,
    tenant: '',
    logout: logout,
    saveToken: saveTokenToCookie,
  };
};
