import React, { useCallback, useState } from 'react';
import { IActivity } from 'domain/entity/IActivity';
import { makeListActivities } from '../usecases';
import ActivityPage from 'presentation/pages/Activity';
import { ActivityFilterParams } from 'domain/usecases/activity/list-activities-use-case';

export const makeActivityPage = (): React.ReactElement => {
  const [activities, setActivities] = useState<IActivity[]>([]);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const fetchData = useCallback(
    async (params: ActivityFilterParams) => {
      try {
        setLoading(true);

        const { data, lastPage, page } = await makeListActivities().execute({
          ...params,
        });

        setActivities(data);
        setPage(page);
        setLastPage(lastPage);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setActivities, setPage, setLastPage]
  );

  return (
    <ActivityPage
      page={page}
      lastPage={lastPage}
      setPage={setPage}
      fetchData={fetchData}
      loading={loading}
      activities={activities}
      setActivities={setActivities}
    />
  );
};
