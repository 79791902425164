import React, { ReactElement } from 'react';
import * as T from './types';
import * as S from './styles';
import Label from '../Label';
import { generateValidations } from './actions';
import { ErrorMessage } from '@hookform/error-message';

const Input = (props: T.InputProps): ReactElement => {
  const {
    name,
    onKeyPress,
    value,
    placeholder,
    label,
    type,
    autoComplete,
    onChange,
    required,
    errors,
    defaultValue,
    errorMessage,
    full = false,
    mask,
    register,
    onEnter,
    validations,
    hidden,
    disabled,
    autoFocus,
    accept,
    height,
  } = props;

  const styles = {
    full,
    height,
  };

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value: currentValue } = e.target;

    if (mask) {
      const maskedValue = mask(currentValue);

      e.target.value = maskedValue;
    }

    onChange && onChange(e);
  }

  return (
    <S.Container {...styles}>
      {label && <Label text={label} />}

      <S.Input
        {...styles}
        data-testid="input-text"
        required={required}
        disabled={disabled}
        error={(errors && errors[name]) || errorMessage}
        autoComplete={autoComplete}
        type={type}
        name={name}
        value={value}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            onEnter && onEnter(e);
          }
          onKeyPress && onKeyPress(e);
        }}
        placeholder={placeholder}
        defaultValue={defaultValue}
        hidden={hidden}
        autoFocus={autoFocus}
        accept={accept}
        onChange={handleOnChange}
        {...(register &&
          register(name, validations && generateValidations(validations)))}
      />
      {errorMessage && <p>{errorMessage}</p>}

      {errors && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => <p>{message}</p>}
        />
      )}
    </S.Container>
  );
};

export default Input;
