import React, { ReactElement } from 'react';
import { Spinner } from 'mino-ui';
import LogoMobile from 'presentation/assets/icons/taskflow.png';
import { LoadingContent, LoadingWrapper } from './styles';

export const GlobalLoading = (): ReactElement => {
  return (
    <LoadingWrapper>
      <LoadingContent>
        <img src={LogoMobile} width={250} />
        <Spinner colorScheme="blue" size="small" />
      </LoadingContent>
    </LoadingWrapper>
  );
};
