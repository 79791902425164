import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { UnexpectedError } from 'domain/errors';
import {
  SendCustomMailRequest,
  SendCustomMailResponse,
  SendCustomMailUseCase,
} from 'domain/usecases/mail/send-mail-custom-use-case';

export class RemoteSendCustomMailUseCase implements SendCustomMailUseCase {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient,
    private readonly toastService: ToastService
  ) {}

  async execute(
    request: SendCustomMailRequest
  ): Promise<SendCustomMailResponse> {
    const response = await this.httpClient.request({
      method: 'post',
      url: this.url,
      body: {
        to: request.to,
        subject: request.subject,
        body: request.message,
        attachments: request.files,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        this.toastService.show('Email enviado com sucesso!', {
          type: 'success',
        });
        return response.body as SendCustomMailResponse;

      case HttpStatusCode.badRequest:
        if (
          response.body?.message ===
          'Você precisa configurar um email personalizado!'
        ) {
          this.toastService.show(
            'Você precisa configurar um email personalizado!',
            {
              type: 'error',
            }
          );
        } else {
          this.toastService.show('Erro ao enviar email', {
            type: 'error',
          });
        }

        throw new UnexpectedError('Erro ao enviar email');
      default:
        this.toastService.show('Erro ao enviar email', {
          type: 'error',
        });
        throw new UnexpectedError('Erro ao enviar email');
    }
  }
}
