import React, { ReactElement, useContext, useEffect, useState } from 'react';
import * as S from './pipe-styles';
import { FunnelResponse } from 'domain/usecases/funnel/list-funnels-use-case';
import { makeListDealsUseCase } from 'main/factories/usecases';
import DealContext from '../contexts/DealContext';
import { PipeList } from './pipe/components';
import PipeDealContext, {
  ChangeResultProps,
} from '../contexts/PipeDealContext';
import { IDeal } from 'domain/entity/IDeal';
import ChangeResultTemplate from '../shared/ChangeResult';
import { PipeListSkeleton } from 'presentation/shared/templates/Skeletons/PipeListSkeleton';

interface PipeProps {
  funnels: FunnelResponse[];
  loading: boolean;
}

export interface PipeDeals {
  deals: IDeal[];
  funnel_id: string;
  lastPage: number;
  page: number;
  totalValue?: number;
  total?: number;
}

const DealPipeline = (props: PipeProps): ReactElement => {
  const { funnels } = props;
  const { filter } = useContext(DealContext);
  const [deals, setDeals] = useState<PipeDeals[]>([]);

  const { fetchFunnels } = useContext(DealContext);

  const [openChangeResult, setOpenChangeResult] = useState<ChangeResultProps>({
    open: false,
    type: 'sell',
    dealId: '',
  });

  const [loading, setLoading] = useState(false);

  async function fetchDeals(): Promise<void> {
    try {
      setLoading(true);
      const promises = funnels.map(async (funnel) => {
        const {
          data: deals,
          page,
          lastPage,
          totalValue,
          total,
        } = await makeListDealsUseCase().execute({
          ...filter,
          funnel_id: funnel.id,
          sort_column: 'updated_at',
          sort_direction: 'desc',
          page: 1,
        });

        return {
          deals,
          funnel_id: funnel.id,
          page,
          lastPage,
          totalValue,
          total,
        };
      });

      const deals = await Promise.all(promises);
      setDeals(deals);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchDeals();
  }, [funnels, filter]);

  return (
    <PipeDealContext.Provider
      value={{
        deals,
        setDeals,
        fetchDeals,
        loadingDeals: loading,
        setOpenChangeResult,
      }}
    >
      {loading ? (
        <PipeListSkeleton />
      ) : (
        <S.Container>
          <S.PipelineWrapper>
            {funnels.map((funnel) => (
              <PipeList key={funnel.id} funnel={funnel} />
            ))}
          </S.PipelineWrapper>
        </S.Container>
      )}

      <ChangeResultTemplate
        callback={() => fetchFunnels()}
        dealId={openChangeResult.dealId || ''}
        open={openChangeResult.open}
        onOpenChange={(v) =>
          setOpenChangeResult({ ...openChangeResult, open: v })
        }
        type={openChangeResult.type}
      />
    </PipeDealContext.Provider>
  );
};

export default DealPipeline;
