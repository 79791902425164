import React, { ReactElement, useEffect, useRef, useState } from 'react';
import Checkbox from '../Checkbox';
import Icon from '../Icon';
import * as S from './styles';
import * as T from './types';
import Input from '../Input';
import { Avatar, Box, Label, PopOver, Spinner } from 'components';
import { Trigger } from './atoms/Trigger';

const Dropdown = (props: T.GroupListProps): ReactElement => {
  const {
    items: initialItems,
    defaultValue,
    maxHeight,
    onChange,
    multi = false,
    onClick,
    minWidth,
    searchable,
    onSearch,
    trigger = 'default',
    loading,
    placeholder,
    initialSelectedItem,
    align = 'start',
    label,
    side,
    hasAvatar,
  } = props;

  const [open, setOpen] = useState(false);

  const [items, setItems] = useState<T.GroupListItem[]>([]);

  useEffect(() => {
    setItems(initialItems);
  }, [initialItems]);

  const [selectedValue, setSelectedValue] = useState<string | string[]>(
    defaultValue || ''
  );

  const styles = {
    maxHeight: maxHeight,
  };

  function handleSearch(value: string) {
    if (onSearch) {
      onSearch(value);
    }

    if (value) {
      setItems(
        initialItems.filter((item) =>
          item.label.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setItems(initialItems);
    }
  }

  function handleChange(value: string) {
    if (multi) {
      if (Array.isArray(selectedValue)) {
        if (selectedValue.includes(value)) {
          setSelectedValue(selectedValue.filter((item) => item !== value));
        } else {
          setSelectedValue([...selectedValue, value]);
        }
      } else {
        setSelectedValue([value]);
      }

      return;
    }

    if (trigger === 'action-button' && !multi) {
      onChange?.(value);
      setTimeout(() => setOpen(false), 100);
      return;
    }

    setSelectedValue(value);
    setTimeout(() => setOpen(false), 100);
  }

  useEffect(() => {
    if (onChange && selectedValue) {
      onChange(selectedValue);
    }
  }, [selectedValue]);

  const itemRefs = useRef<(HTMLElement | null)[]>([]);

  useEffect(() => {
    if (itemRefs.current && itemRefs.current.length > 0) {
      itemRefs.current[0]?.focus();
    }
  }, []);

  function handleKeyDown(event: React.KeyboardEvent, index: number) {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        if (index > 0) {
          itemRefs.current[index - 1]?.focus();
        }
        break;
      case 'ArrowDown':
        event.preventDefault();
        if (index < items.length - 1) {
          itemRefs.current[index + 1]?.focus();
        }
        break;
      case 'Enter':
        event.preventDefault();
        handleChange(items[index].value);
        break;
      default:
        break;
    }
  }

  const groupedItems = items.reduce<Record<string, T.GroupListItem[]>>(
    (result, item) => {
      const group = item.group || '';
      if (result[group]) {
        result[group].push(item);
      } else {
        result[group] = [item];
      }
      return result;
    },
    {}
  );

  const ref = useRef<HTMLDivElement>(null);

  return (
    <S.Container ref={ref}>
      <PopOver
        preventActiveTriggerStyles
        align={align}
        noWrap
        side={side}
        open={open}
        onOpenChange={setOpen}
        trigger={
          <Box flexDirection="column" alignItems="baseline">
            {label ? <Label text={label} /> : null}
            <Trigger
              setSelectedValue={setSelectedValue}
              hasAvatar={hasAvatar}
              multi={multi}
              open={open}
              setOpen={setOpen}
              placeholder={placeholder}
              type={trigger}
              items={items}
              selectedValue={selectedValue}
              initialSelectedItem={initialSelectedItem}
            />
          </Box>
        }
        content={
          <S.Content minWidth={minWidth} maxHeight={styles.maxHeight}>
            {searchable ? (
              <S.SearchWrapper>
                <Icon iconName="Search20Regular" />
                <Input
                  height={32}
                  name="search"
                  onChange={(e) => handleSearch(e.target.value || '')}
                  autoFocus
                  placeholder="Buscar"
                />
              </S.SearchWrapper>
            ) : null}

            {loading ? (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '200px',
                  maxWidth: '200px',
                }}
              >
                <Spinner />
              </Box>
            ) : (
              Object.entries(groupedItems).map(([group, items]) => (
                <S.Group key={group}>
                  {group ? <S.GroupLabel>{group}</S.GroupLabel> : null}

                  {items.map(
                    (item, index) =>
                      !item.hidden && (
                        <S.Item
                          key={index}
                          ref={(el) => (itemRefs.current[index] = el)}
                          tabIndex={0}
                          onKeyDown={(event) => handleKeyDown(event, index)}
                          onClick={() => {
                            handleChange(item.value);
                            onClick?.(item.value);
                          }}
                          selected={
                            Array.isArray(selectedValue)
                              ? selectedValue.includes(item.value)
                              : selectedValue === item.value
                          }
                          className={`${multi ? 'multi' : 'single'}`}
                          disabled={item.disabled}
                        >
                          {hasAvatar ? (
                            <Avatar
                              name={item.label || item.value}
                              image={item.avatar}
                            />
                          ) : null}

                          {multi ? (
                            <Checkbox
                              checked={selectedValue.includes(item.value)}
                              name={item.value}
                            />
                          ) : null}

                          {item.icon ? (
                            <Icon iconName={item.icon} color={item.iconColor} />
                          ) : null}

                          <S.ItemLabel>
                            <S.ItemText hasIcon={!!item.icon}>
                              {item.label}
                            </S.ItemText>
                            <S.ItemDescription>
                              {item.description}
                            </S.ItemDescription>
                          </S.ItemLabel>
                        </S.Item>
                      )
                  )}
                </S.Group>
              ))
            )}
          </S.Content>
        }
      />
    </S.Container>
  );
};

export default Dropdown;
