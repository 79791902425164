import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Button, Icon, Input, Select, Typography } from 'mino-ui';
import * as S from '../styles';
import { useServices } from 'main/hooks/useServices';
import { ISale } from 'domain/entity/ISale';
import { maskReal } from 'main/helpers/format-to-real';
import { useDebouncedEffect } from 'presentation/hooks/useDebouncedEffect';

type DealContactProps = {
  setDealValues: (value: number) => void;
  sales?: ISale[];
  setSales: (sales: ISale[]) => void;
  hideValueInput?: boolean;
};

export const DealSales = (props: DealContactProps): ReactElement => {
  const { setSales, setDealValues, sales, hideValueInput } = props;

  const [searchService, setSearchService] = useState<string>('');

  const [hasSales, setHasSales] = useState(false);

  useEffect(() => {
    setHasSales(sales && sales?.length > 0 ? true : false);
  }, [sales]);

  const { services: products, fetchServices } = useServices();

  function calculateTotalWithDiscountApplied(
    price: number,
    quantity: number,
    discount: number
  ) {
    const total = price * quantity;
    const discountApplied = total * (discount / 100);
    return total - discountApplied;
  }

  function handleSaleChanges(field: string, value: string, index: number) {
    const newSales = sales?.map((sale, i) => {
      if (i === index) {
        return {
          ...sale,
          [field]: value,
          product: sale.product,
          total: calculateTotalWithDiscountApplied(
            sale?.product?.price || 0,
            field === 'quantity' ? Number(value) : sale.quantity,
            field === 'discount' ? Number(value) : sale.discount
          ),
        };
      }
      return sale;
    });

    if (newSales) {
      setSales(newSales);
    }
  }

  function handleProductChange(productId: string, index: number) {
    const product = products.find((p) => p.id === productId);

    if (!product) return;

    if (product) {
      const newSales = sales?.map((sale, i) => {
        if (i === index) {
          return {
            ...sale,
            product_id: product.id,
            product: {
              id: product.id,
              name: product.name,
              price: product.price || 0,
            },
            total: calculateTotalWithDiscountApplied(
              product.price || 0,
              sale.quantity,
              sale.discount
            ),
          };
        }
        return sale;
      });

      if (newSales) {
        setSales(newSales);
      }
    }
  }

  useDebouncedEffect(
    () => {
      fetchServices(searchService);
    },
    [searchService],
    300
  );

  if (!hasSales && !hideValueInput) {
    return (
      <Box flexDirection="column" gap={16}>
        <S.Separator />

        <Input
          defaultValue="R$ 0,00"
          mask={(v) => {
            const value = v?.replace(/\D/g, '');
            setDealValues(Number(value) / 100);
            return maskReal(v || '0');
          }}
          name="value"
          label="Valor"
        />

        <Button
          onClick={() => setHasSales(true)}
          variant="outline"
          size="small"
          text="Adicionar Produto"
          icon="AddCircle20Regular"
        />
      </Box>
    );
  }

  return (
    <Box flexDirection="column" gap={16}>
      <S.Separator />
      <Box gap={8}>
        <Icon iconName="Box24Regular" color="blue" />
        <Typography variant="h3" fontSize={16} noWrap>
          Produtos ou Serviços
        </Typography>
      </Box>

      {sales?.map((sale, index) => {
        const isSaleFromInitialSales = sales?.find((s) => s.deal_id === '0')
          ? false
          : true;

        return (
          <Box key={sale.id} flexDirection="column" gap={16}>
            <S.ProductWrap>
              <Select
                disabled={isSaleFromInitialSales}
                value={
                  isSaleFromInitialSales
                    ? {
                        label: sale?.product?.name,
                        value: sale.product_id,
                      }
                    : undefined
                }
                onInputChange={(v) => setSearchService(v)}
                name="product_id"
                fullWidth
                placeholder="Selecione"
                label="Produto"
                options={products.map((p) => ({
                  label: p.name,
                  value: p.id,
                }))}
                required
                onChange={(v) =>
                  v?.value && handleProductChange(v.value, index)
                }
              />
              <Input
                full
                label="Preço"
                placeholder="R$0,00"
                name="service_price"
                value={sale?.product?.price?.toString()}
                disabled
              />
              <Input
                full
                label="Qnt."
                placeholder="Ex:1"
                name="quantity"
                value={sale.quantity?.toString()}
                type="number"
                onChange={(e) =>
                  handleSaleChanges('quantity', e.target.value, index)
                }
              />
              <Input
                onChange={(e) =>
                  handleSaleChanges('discount', e.target.value, index)
                }
                full
                value={sale.discount?.toString()}
                label="Desconto %"
                placeholder="Ex:10%"
                name="discount"
                type="number"
              />

              <Box
                style={{
                  position: 'relative',
                }}
              >
                <Input
                  full
                  label="Total"
                  placeholder="R$0,00"
                  name="total"
                  disabled
                  mask={(v) => maskReal(v || '0')}
                  value={sale.total?.toString()}
                />
                <S.RemoveButton
                  onClick={() => {
                    const newSales = sales.filter((s) => s.id !== sale.id);
                    setSales(newSales);
                  }}
                  type="button"
                >
                  <Icon iconName="Delete20Regular" />
                </S.RemoveButton>
              </Box>
            </S.ProductWrap>
          </Box>
        );
      })}

      <Box justifyContent="space-between">
        <Button
          text="Adicionar"
          icon="AddCircle20Regular"
          iconColor="dark"
          size="small"
          type="button"
          variant="ghost"
          onClick={() =>
            setSales([
              ...(sales || []),
              {
                id: Math.floor(10000000 + Math.random() * 90000000).toString(),
                discount: 0,
                quantity: 1,
                product_id: '0',
                deal_id: '0',
                total: 0,
                product: {
                  id: '0',
                  name: '',
                  price: 0,
                },
              },
            ])
          }
        />

        <Box
          style={{
            paddingRight: 16,
          }}
        >
          <Typography variant="body" color="gray" fontSize={12}>
            Total: R$ {sales?.reduce((acc, sale) => acc + sale.total, 0)}
          </Typography>
        </Box>
      </Box>

      <S.Separator />
    </Box>
  );
};
