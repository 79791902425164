import React, { ReactElement, useEffect, useState } from 'react';
import { Icon, Typography } from 'mino-ui';
import ServicesConfig from '../../Services/component';
import GroupsConfig from '../../Groups/component';
import { Integrations } from '../../Integrations/list/component';
import UsersConfig from '../../Users/component';
import * as S from './styles';
import FunnelsConfig from '../../Funnels/component';
import TeamsConfig from '../../Teams/component';
import ChannelsConfig from '../../Channels/component';
import TagsConfig from '../../Tags/component';
import LossConfig from '../../Losses/component';
import CustomFields from '../../CustomFields/component';
import ImportBatchConfig from '../../ImportBatch/component';

export type TriggerProps = {
  id: string;
  text: string;
  icon?: string;
};

export type ConfigProps = {
  id: string;
  text: string;
  icon?: string;
  triggers: TriggerProps[];
};

export type CustomTabProps = {
  configs: ConfigProps[];
  defaultActive: string;
  active?:
    | 'general'
    | 'comercial'
    | 'admin'
    | 'integrations'
    | 'integrations/:id';
};

export enum IconColors {
  dark = 'dark',
  blue = 'blue',
  gray = 'gray',
}

const CustomTabs = (props: CustomTabProps): ReactElement => {
  const { configs, defaultActive } = props;

  const [active, setActive] = useState('');

  const [view, setView] = useState('');

  const [open, setOpen] = useState(false);

  function renderView(view: string) {
    switch (view) {
      case 'users':
        return <UsersConfig />;
      case 'groups':
        return <GroupsConfig />;
      case 'services':
        return <ServicesConfig />;
      case 'funnels':
        return <FunnelsConfig />;
      case 'teams':
        return <TeamsConfig />;
      case 'channels':
        return <ChannelsConfig />;
      case 'tags':
        return <TagsConfig />;
      case 'losses':
        return <LossConfig />;
      case 'appStore':
        return <Integrations />;
      case 'fields':
        return <CustomFields />;
      case 'ImportBatch':
        return <ImportBatchConfig />;
    }
  }

  useEffect(() => {
    if (defaultActive != '') {
      setActive(defaultActive);

      configs.map((e) => {
        if (e.id == defaultActive) {
          setView(e.triggers[0].id);
        }
      });
    }
  }, [defaultActive, configs]);

  return (
    <S.FLexArea>
      <S.TriggerWrap open={open}>
        <S.Title>
          <Typography variant="h4" fontSize={18} lineHeight={24}>
            Configurações
          </Typography>
        </S.Title>
        {configs?.map((t) => {
          return (
            <>
              <S.Divider />
              <S.TriggerBox>
                <S.ConfigType
                  active={active === t.id ? true : false}
                  onClick={() => {
                    setView(t.triggers[0].id), setActive(t.id);
                    setOpen(false);
                  }}
                >
                  <Icon iconName={`${t.icon}`} color={IconColors.dark} />
                  <Typography variant="body" color={'dark'} weight="bold">
                    {t.text}
                  </Typography>
                </S.ConfigType>
                <S.Triggers>
                  {t.triggers.map((trigger) => {
                    return (
                      <>
                        <S.Trigger
                          active={view === trigger.id ? true : false}
                          onClick={() => {
                            setActive(t.id);
                            setView(trigger.id);
                            setOpen(false);
                          }}
                        >
                          <Icon
                            iconName={`${trigger.icon}`}
                            color={
                              view === trigger.id
                                ? IconColors.blue
                                : IconColors.gray
                            }
                          />
                          <Typography
                            variant="body"
                            color={view === trigger.id ? 'primary' : 'gray'}
                            weight="bold"
                          >
                            {trigger.text}
                          </Typography>
                        </S.Trigger>
                      </>
                    );
                  })}
                </S.Triggers>
              </S.TriggerBox>
            </>
          );
        })}
      </S.TriggerWrap>

      <S.ContentBox>{renderView(view)}</S.ContentBox>
    </S.FLexArea>
  );
};
export default CustomTabs;
