/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { makeListContactUseCase } from 'main/factories/usecases';
import { IContact } from 'domain/entity/IContact';

type UseContactsType = {
  contacts: IContact[] | [];
  fetchContacts: (search?: string) => Promise<void>;
  loading: boolean;
};

export const useContacts = (): UseContactsType => {
  const [contacts, setContacts] = useState<IContact[]>([]);
  const [loading, setLoading] = useState(false);

  async function fetchContacts(search?: string): Promise<void> {
    try {
      setLoading(true);
      const { data } = await makeListContactUseCase().execute({
        search,
        context: 'self',
      });
      setContacts(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchContacts();
  }, []);

  return { contacts, fetchContacts, loading };
};
