import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { ICompany } from 'domain/entity/ICompany';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import { GetSingleCompanyUseCase } from 'domain/usecases/company/get-company-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteGetCompany implements GetSingleCompanyUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(): Promise<ICompany> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      method: 'get',
      url: this.url,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Tenant: tenant,
      },
    });

    const company = response.body;

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return {
          id: company.id,
          name: company.name,
          phone_number: company.phone_number,
          email: company.email,
          cnpj: company.cnpj,
          site: company.site,
          address: company.address,
          city: company.city,
          state: company.state,
          description: company.description,
          deals: company.products,
          contacts: company.contacts,
          tags: company.tags,
          created_at: company.created_at,
          user_id: company.user_id,
          user: company.user,
          custom_fields: company.custom_fields,
        };

      case HttpStatusCode.unauthorized:
        this.toastService.show('Não autorizado', {
          type: 'error',
        });
        throw new NotAuthorizedError('Unauthorized');
      default:
        this.toastService.show('Erro no servidor', {
          type: 'error',
        });
        throw new UnexpectedError('Unexpected error');
    }
  }
}
