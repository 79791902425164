export const global_permissions = [
  'users',
  'roles',
  'teams',
  'tags',
  'channels',
  'funnels',
  'loss-reasons',
  'products',
  'contacts',
  'deals',
  'companies',
  'custom-fields',
  'activities',
];

export const global_permission_modes = ['write', 'read', 'delete'];

export const hasPermission = (
  permissions: string[],
  resource: string,
  mode: 'write' | 'read' | 'delete' | 'update'
): boolean => {
  const permission = `${resource}:${mode}`;
  return permissions.includes(permission);
};

export const translatePermission = (permission: string): string => {
  switch (permission) {
    case 'users':
      return 'Usuários';
    case 'roles':
      return 'Funções';
    case 'teams':
      return 'Equipes';
    case 'tags':
      return 'Tags';
    case 'channels':
      return 'Canais';
    case 'funnels':
      return 'Funis';
    case 'loss-reasons':
      return 'Motivos de Perda';
    case 'products':
      return 'Produtos';
    case 'contacts':
      return 'Contatos';
    case 'deals':
      return 'Negócios';
    case 'companies':
      return 'Empresas';
    case 'custom-fields':
      return 'Campos Personalizados';
    case 'field-registries':
      return 'Registros de Campos';
    case 'activities':
      return 'Atividades';
    default:
      return '';
  }
};
