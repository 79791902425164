import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { collaborators, segments, whyINeedCRM } from 'config/consts/register';
import { Button, Select, Spacer } from 'mino-ui';
import { SignUpContext } from 'presentation/contexts';

const StepTwo = (): ReactElement => {
  const [validate, SetValidate] = useState(false);

  const {
    setStep,
    setBreadCrumb,
    step,
    setTitle,
    handleSelectInputChange,
    form,
  } = useContext(SignUpContext);

  function handleChangeStep(): void {
    setStep(3);
  }

  useEffect(() => {
    setTitle({
      title: 'Crie uma conta',
      subtitle: 'já possui uma conta?',
    });
  }, []);

  useEffect(() => {
    setBreadCrumb([
      {
        title: 'Meus dados',
        action: () => undefined,
        active: step === 1 ? true : false,
      },
      {
        title: 'Dados da empresa',
        action: () => undefined,
        active: step === 2 ? true : false,
      },
      {
        title: 'Senha',
        action: () => undefined,
        active: step === 3 ? true : false,
      },
    ]);
  }, []);

  useEffect(() => {
    if (form.segment !== '' && form.crmFor !== '' && form.companySize !== '') {
      SetValidate(true);
    } else {
      SetValidate(false);
    }
  }, [form]);

  return (
    <>
      <Select
        fullWidth
        onChange={(v) =>
          v?.value && handleSelectInputChange('segment', v.value)
        }
        label="Qual o segmento da empresa?"
        placeholder="Selecione"
        options={segments}
      />

      <Spacer height={24} />

      <Select
        fullWidth
        onChange={(v) =>
          v?.value && handleSelectInputChange('companySize', v.value)
        }
        label="Qual a quantidade de funcionários?"
        placeholder="Selecione"
        options={collaborators}
      />

      <Spacer height={24} />

      <Select
        fullWidth
        onChange={(v) => v?.value && handleSelectInputChange('crmFor', v.value)}
        label="Para qual finalidade você busca um CRM?"
        placeholder="Selecione"
        options={whyINeedCRM}
      />
      <Spacer height={24} />

      <Button
        disabled={!validate}
        full
        text="Avançar"
        onClick={() => handleChangeStep()}
      />
    </>
  );
};

export default StepTwo;
